import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState as useStateLegacy } from 'react';

export type StateSetter<S> = Dispatch<SetStateAction<S> & S>;

export function useStateDiff<S>(initialState: S | (() => S)): {
  state: S;
  oldState: S | undefined;
  setState: StateSetter<S>;
} {
  const [state, setStateLegacy] = useStateLegacy<S>(initialState);
  const [oldState, saveOldState] = useStateLegacy<S>();
  const isStateChanged = useMemo(() => {
    let incomingState = valueForComparison(state);
    let savedOldState = valueForComparison(oldState);

    return incomingState !== savedOldState;
  }, [state, oldState]);
  const setStateDelta = useCallback(
    (value: SetStateAction<S> & S): void => {
      let requestedState = valueForComparison(value);
      let existingState = valueForComparison(state);

      if (requestedState !== existingState) {
        return setStateLegacy(value);
      }
    },
    [setStateLegacy, state]
  );

  useEffect(() => {
    if (isStateChanged) {
      saveOldState(state);
    }
  }, [isStateChanged, state, saveOldState]);

  return {
    state: useMemo(() => Object.freeze(state), [state]),
    oldState: useMemo(() => Object.freeze(oldState), [oldState]),
    setState: useMemo(() => Object.freeze(setStateDelta), [setStateDelta]),
  };
}

function valueForComparison<S>(value: S): string | S {
  return typeof value == 'object' ? JSON.stringify(value ?? null) : value;
}
