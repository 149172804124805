import { ClipboardCopyIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from '.';
import { NotificationLevel, useNotifications } from '../../global/Notifications';

const NOTIFICATION_LIFETIME_IN_MS = 3_000;

const copyUsingNavigator = async (textToCopy: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    return true;
  } catch (exception) {
    console.warn('Copy to clipboard failed [1].', exception);
    return false;
  }
};

const copyUsingDOMQuery = (textToCopy: string): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    try {
      if (!window.document.queryCommandSupported || !window.document.queryCommandSupported('copy')) {
        throw new Error('Document query command not supported.');
      }

      let textarea = document.createElement('textarea');

      textarea.textContent = textToCopy;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge

      try {
        document.body.appendChild(textarea);
        textarea.select();

        // Security exception may be thrown by some browsers
        if (!document.execCommand('copy')) {
          throw new Error('Error executing document copy command.');
        }
      } finally {
        document.body.removeChild(textarea);
      }

      resolve(true);
    } catch (exception) {
      console.warn('Copy to clipboard failed [1].', exception);
      resolve(false);
    }
  });
};

type ClipboardCopyLogic = (textToCopy: string) => Promise<boolean>;

export type CopyToClipboardButtonProps = Omit<
  Exclude<ButtonProps, React.AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps>,
  'onClick'
> & {
  targetText: string;
  clipboardLogic?: ClipboardCopyLogic;
  title?: string;
  className?: string;
  notifyMessage?: string;
};

export const copyToClipboard: ClipboardCopyLogic = async (textToCopy: string) => {
  return copyUsingNavigator(textToCopy).then((isCopied) => isCopied || copyUsingDOMQuery(textToCopy));
};

function CopyToClipboardButton({
  targetText,
  clipboardLogic,
  title,
  className,
  notifyMessage,
  ...properties
}: CopyToClipboardButtonProps) {
  const { add: addNotification } = useNotifications();
  const copyTextLogic = clipboardLogic ?? copyToClipboard;
  const handleCopyEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const isTextCopied = await copyTextLogic(targetText);

    if (isTextCopied && notifyMessage) {
      addNotification({
        headerText: 'Copied!',
        message: notifyMessage,
        level: NotificationLevel.Info,
        timeoutInMs: NOTIFICATION_LIFETIME_IN_MS,
      });
    }

    return isTextCopied;
  };

  const typedProperties = properties as React.ButtonHTMLAttributes<HTMLButtonElement>;

  return (
    <>
      <Button {...typedProperties} title={title || 'Copy to Clipboard'} onClick={handleCopyEvent} className={className}>
        <ClipboardCopyIcon className="h-6 w-6" />
      </Button>
    </>
  );
}

export default CopyToClipboardButton;
