const NetSPIProvidedPlaybookIcon = (props: React.SVGAttributes<SVGElement>): React.ReactElement => {
  return (
    <svg
      id="Group_2709"
      data-name="Group 2709"
      xmlns="http://www.w3.org/2000/svg"
      width="19.253"
      height="19.256"
      viewBox="0 0 19.253 19.256"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2324" data-name="Rectangle 2324" width="19.253" height="19.256" fill="#a5b0bd" />
        </clipPath>
      </defs>
      <g id="Group_2708" data-name="Group 2708" clipPath="url(#clip-path)">
        <path
          id="Path_1227"
          data-name="Path 1227"
          d="M5.887,27.346,3.452,24.907a.6.6,0,0,0-.843,0L.174,27.346a.6.6,0,0,0,0,.845l2.435,2.439a.6.6,0,0,0,.843,0L5.887,28.19a.6.6,0,0,0,0-.845"
          transform="translate(0 -20.295)"
          fill="#a5b0bd"
        />
        <path
          id="Path_1228"
          data-name="Path 1228"
          d="M36.852,8.953a.594.594,0,0,0-.148-.105V6a.594.594,0,0,0,.148-.105l2.435-2.439a.6.6,0,0,0,0-.845L36.852.174a.6.6,0,0,0-.843,0L33.574,2.613a.6.6,0,0,0-.131.2h-2.8a.6.6,0,0,0-.131-.2L28.079.174a.6.6,0,0,0-.843,0L24.8,2.613a.6.6,0,0,0,0,.845L27.236,5.9A.594.594,0,0,0,27.384,6V8.847a.594.594,0,0,0-.148.105L24.8,11.391a.6.6,0,0,0,0,.845l2.435,2.439a.6.6,0,0,0,.843,0l2.435-2.439a.6.6,0,0,0,0-.845L28.079,8.953a.594.594,0,0,0-.148-.105V6a.594.594,0,0,0,.148-.105l2.435-2.439a.6.6,0,0,0,.079-.1h2.9a.6.6,0,0,0,.079.1L36.009,5.9A.591.591,0,0,0,36.157,6V8.847a.591.591,0,0,0-.148.105l-2.435,2.439a.6.6,0,0,0,0,.845l2.435,2.439a.6.6,0,0,0,.843,0l2.435-2.439a.6.6,0,0,0,0-.845Z"
          transform="translate(-20.208 0.001)"
          fill="#a5b0bd"
        />
        <path
          id="Path_1229"
          data-name="Path 1229"
          d="M12.312,33.654a.6.6,0,0,0-.163-.114v-2.8a.589.589,0,0,0,.163-.114l2.435-2.439a.6.6,0,0,0,0-.845l-2.435-2.439a.6.6,0,0,0-.843,0L9.034,27.347a.6.6,0,0,0,0,.845l2.435,2.439a.588.588,0,0,0,.133.1v2.829a.593.593,0,0,0-.133.1L9.034,36.093a.591.591,0,0,0-.1.143H5.989a.587.587,0,0,0-.1-.143L3.452,33.654a.6.6,0,0,0-.843,0L.174,36.093a.6.6,0,0,0,0,.845l2.435,2.439a.6.6,0,0,0,.843,0l2.435-2.439a.586.586,0,0,0,.108-.154H8.926a.589.589,0,0,0,.108.154l2.435,2.439a.6.6,0,0,0,.843,0l2.435-2.439a.6.6,0,0,0,0-.845Z"
          transform="translate(0 -20.295)"
          fill="#a5b0bd"
        />
      </g>
    </svg>
  );
};

export default NetSPIProvidedPlaybookIcon;
