import { Link } from 'react-router-dom';
import React from 'react';
import useErrors from '../../hooks/useErrors';
import useHttp from '../../hooks/useHttp';
import {
  Alert,
  Button,
  ConfirmButton,
  DefaultErrorAlert,
  mergeClasses,
  PageSection,
  PasswordInput,
  TextInput,
} from '../common';
import { ErrorMapType, LoadingIndicator } from '../common';
import CopyToClipboardButton from '../common/CopyToClipboardButton';
import { ClockIcon, RefreshIcon, TrashIcon } from '@heroicons/react/outline';
import * as formatUtility from '../../util/formattingHelpers';

const apiPageSectionTitle = 'API';
const apiPageSectionSubtitle = (
  <>
    Configure API settings. For more information, check out our{' '}
    <Link target="_blank" to="/apidocs" className="text-blue-600 hover:underline">
      API Docs!
    </Link>
  </>
);
const UNHASHED_TOKEN_LENGTH = 64;

enum ApiPage {
  Landing,
  Token,
}

interface ApiTokenState {
  id?: string;
  generatedAt: Date | null;
}

type NavigateCallback = (event: React.MouseEvent<HTMLButtonElement>) => void;
type TokenCallback = (event: React.MouseEvent<HTMLButtonElement>) => void;

const UserAPI: React.FC = () => {
  const [httpHandling404] = useHttp(true);
  const [httpIgnoring404] = useHttp(true, [404]);
  const [isLoading, setisLoading] = React.useState<boolean>(true);
  const [selectedPage, setSelectedPage] = React.useState<ApiPage>(ApiPage.Landing);
  const [apiTokenInfo, setApiTokenInfo] = React.useState<ApiTokenState>({ generatedAt: null });
  const [generateTokenInProgress, setGenerateTokenInProgress] = React.useState<boolean>(false);
  const [deleteTokenInProgress, setDeleteTokenInProgress] = React.useState<boolean>(false);
  const { errors, catchErrors, clear: clearErrors } = useErrors();
  const isTokenDisplayable = React.useMemo<boolean>(() => 'id' in apiTokenInfo, [apiTokenInfo]);
  const displayedTokenDate = React.useMemo<string | null>(
    () => (apiTokenInfo.generatedAt ? formatUtility.formatLongDateTime(apiTokenInfo.generatedAt) : null),
    [apiTokenInfo.generatedAt]
  );
  const setModelData = React.useCallback(
    (modelData: any) => {
      let apiTokenInfo: ApiTokenState = { ...modelData };

      if (modelData.generatedAt) {
        apiTokenInfo.generatedAt = new Date(Date.parse(modelData.generatedAt));
      }

      setApiTokenInfo(apiTokenInfo);
    },
    [setApiTokenInfo]
  );
  const generateApiTokenAsync = React.useCallback(() => {
    return httpHandling404.put('/profile/api/token').then((response) => setModelData(response.data));
  }, [httpHandling404, setModelData]);
  const deleteApiTokenAsync = React.useCallback(() => {
    return httpHandling404.delete('/profile/api/token').then(() => setModelData({ generatedAt: null }));
  }, [httpHandling404, setModelData]);
  const generateApiTokenEventLogic = React.useCallback(() => {
    if (generateTokenInProgress) {
      return;
    }

    clearErrors();
    setGenerateTokenInProgress(true);
    generateApiTokenAsync()
      .catch(catchErrors)
      .finally(() => {
        setGenerateTokenInProgress(false);
      });
  }, [generateTokenInProgress, clearErrors, setGenerateTokenInProgress, generateApiTokenAsync, catchErrors]);
  const handleNavigateToTokenEvent = React.useCallback<NavigateCallback>(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      clearErrors();
      setSelectedPage(ApiPage.Token);
      generateApiTokenEventLogic();
    },
    [clearErrors, setSelectedPage, generateApiTokenEventLogic]
  );
  const handleGenerateTokenEvent = React.useCallback<TokenCallback>(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      generateApiTokenEventLogic();
    },
    [generateApiTokenEventLogic]
  );
  const handleDeleteTokenEvent = React.useCallback<TokenCallback>(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (deleteTokenInProgress) {
        return;
      }

      clearErrors();
      setDeleteTokenInProgress(true);
      deleteApiTokenAsync()
        .then(() => setSelectedPage(ApiPage.Landing))
        .catch(catchErrors)
        .finally(() => {
          setDeleteTokenInProgress(false);
        });
    },
    [deleteTokenInProgress, clearErrors, setDeleteTokenInProgress, deleteApiTokenAsync, catchErrors]
  );

  React.useEffect(() => {
    httpIgnoring404
      .get('/profile/api/token')
      .then((response) => {
        setModelData(response.data);
        setSelectedPage(ApiPage.Token);
      })
      .catch((error) => {
        switch (error.response.status) {
          case 404:
            setApiTokenInfo({ generatedAt: null });
            setSelectedPage(ApiPage.Landing);
            break;

          default:
            throw error;
        }
      })
      .catch(catchErrors)
      .finally(() => setisLoading(false));
  }, [httpIgnoring404, setSelectedPage, setModelData, catchErrors]);

  return (
    <PageSection title={apiPageSectionTitle} subtitle={apiPageSectionSubtitle}>
      {isLoading ? (
        <LoadingIndicator className="mt-6" />
      ) : (
        <>
          <DefaultErrorAlert errors={errors} />
          {selectedPage === ApiPage.Landing
            ? ApiLandingPage({
                handleNavigateEvent: handleNavigateToTokenEvent,
              })
            : ApiTokenPage({
                apiTokenInfo: apiTokenInfo,
                isTokenDisplayable: isTokenDisplayable,
                displayedTokenDate: displayedTokenDate,
                handleGenerateEvent: handleGenerateTokenEvent,
                handleDeleteEvent: handleDeleteTokenEvent,
                generateInProgress: generateTokenInProgress,
                deleteInProgress: deleteTokenInProgress,
                errors: errors,
              })}
        </>
      )}
    </PageSection>
  );
};

export default UserAPI;

interface LandingPageParams {
  handleNavigateEvent: NavigateCallback;
}

interface TokenPageParams {
  apiTokenInfo: ApiTokenState;
  isTokenDisplayable: boolean;
  displayedTokenDate: string | null;
  handleGenerateEvent: TokenCallback;
  handleDeleteEvent: TokenCallback;
  generateInProgress: boolean;
  deleteInProgress: boolean;
  errors: ErrorMapType;
}

function ApiLandingPage(params: LandingPageParams) {
  return (
    <div className="border-gray-300">
      <Button theme="primary" className="py-1 mt-3" title="Generate Token" onClick={params.handleNavigateEvent}>
        Generate API Token
      </Button>
    </div>
  );
}

export function ApiTokenPage(params: TokenPageParams) {
  return (
    <div className="mt-6 flex flex-col gap-y-2">
      <div className="flex-row space-x-2 flex">
        {!params.isTokenDisplayable && (
          <PasswordInput
            containerClassName="w-full max-w-xl"
            name="CurrentToken"
            value={params.displayedTokenDate ? '*'.repeat(UNHASHED_TOKEN_LENGTH) : ''}
            autoFocus={true}
            errors={params.errors}
            readOnly={true}
            data-testid="current-token-box"
          />
        )}
        {params.isTokenDisplayable && (
          <>
            <TextInput
              containerClassName="w-full max-w-xl"
              id="generatedToken"
              name="GeneratedToken"
              value={params.apiTokenInfo.id}
              autoFocus={true}
              errors={params.errors}
              readOnly={true}
              data-testid="generated-token-box"
            />
            <CopyToClipboardButton
              theme="white"
              className="h-10 w-10 justify-center px-0 py-0 my-0.5"
              title="Copy Token"
              targetText={params.apiTokenInfo.id ?? ''}
              notifyMessage="The API token was copied."
              data-testid="copy-token-button"
            />
          </>
        )}
        {params.displayedTokenDate ? (
          <>
            <ConfirmButton
              theme="white"
              className="h-10 w-10 justify-center px-0 py-0 my-0.5"
              title="Generate Token"
              onClick={params.handleGenerateEvent}
              confirmTitle="Are you sure?"
              confirmMessage="Are you sure you want to replace the current API token?"
              data-testid="generate-token-confirmation-button"
            >
              <RefreshIcon
                className={mergeClasses(
                  'h-6 w-6',
                  {
                    className: 'animate-spin',
                    enable: params.generateInProgress,
                  },
                  'animate-reverse'
                )}
              />
            </ConfirmButton>
            <ConfirmButton
              theme="white"
              className="h-10 w-10 justify-center px-0 py-0 my-0.5"
              title="Delete Token"
              onClick={params.handleDeleteEvent}
              confirmTitle="Are you sure?"
              confirmMessage="Are you sure you want to delete the current API token?"
              data-testid="delete-token-confirmation-button"
            >
              <TrashIcon
                className={mergeClasses('h-6 w-6', { className: 'animate-spin', enable: params.deleteInProgress })}
              />
            </ConfirmButton>
          </>
        ) : (
          <>
            <Button
              theme="white"
              className="h-10 w-10 justify-center px-0 py-0 my-0.5"
              title="Generate Token"
              onClick={params.handleGenerateEvent}
              data-testid="generate-token-button"
            >
              <RefreshIcon
                className={mergeClasses(
                  'h-6 w-6',
                  {
                    className: 'animate-spin',
                    enable: params.generateInProgress,
                  },
                  'animate-reverse'
                )}
              />
            </Button>
          </>
        )}
      </div>
      {params.displayedTokenDate && (
        <div className="inline-flex flex-nowrap text-xs">
          <ClockIcon className="h-3 w-3 my-0.5" />
          &nbsp;Generated on {params.displayedTokenDate}
        </div>
      )}
      {params.isTokenDisplayable && (
        <Alert theme="warning" className="mb-3">
          You will only be able to view and copy this token once, make sure you copy it!
        </Alert>
      )}
    </div>
  );
}
