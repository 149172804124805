import { OrderedMap } from 'immutable';
import { uniqueId } from 'lodash';
import * as React from 'react';
import { NamedAction, NamedActionPayload, generateNamedAction, generateNamedActionWithPayload } from './actions';

const NOTIFICATIONS_LIMIT = 10;

export enum NotificationLevel {
  Success,
  Info,
  Warning,
  Error,
}

type NotificationId = string;

export interface Notification {
  id: string;
  headerText?: string;
  message: string;
  level?: NotificationLevel;
  actionButtonName?: string;
  actionCallback?: () => void;
  timeExtantInMs: number | null;
  timeoutHandle: number | null;
  visible: boolean;
}

type NotificationState = OrderedMap<string, Notification>;

export type NotificationActionPayload = Omit<
  Notification & { timeoutInMs?: number },
  'id' | 'visible' | 'timeoutHandle' | 'timeExtantInMs'
>;

export type ExpireNotificationPayload = {
  id: string;
  timeoutHandle: number;
};

export interface NotificationActions {
  add: (notification: NotificationActionPayload) => void;
  remove: (id: NotificationId) => void;
  clear: () => void;
}

type NotificationsContextProps = {
  notifications: NotificationState;
  dispatch: React.Dispatch<NotificationReducerActions>;
};

const NotificationsContext = React.createContext<NotificationsContextProps | undefined>(undefined);

export function useNotifications(): NotificationActions {
  const ctx = React.useContext(NotificationsContext);
  if (ctx === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider.');
  }

  const { notifications, dispatch } = ctx;

  const clearNotificationTimeout = React.useCallback(
    (id: string) => {
      const timeoutHandle = notifications.get(id)?.timeoutHandle;

      if (timeoutHandle) {
        window.clearTimeout(timeoutHandle);
      }
    },
    [notifications]
  );

  const clearAllNotificationTimeouts = React.useCallback(() => {
    notifications.forEach((notification) => clearNotificationTimeout(notification.id));
  }, [notifications, clearNotificationTimeout]);

  const actions = React.useMemo(
    () => ({
      add: (notification: NotificationActionPayload) => dispatch(generateAddActionPayload(notification)),
      remove: (id: string) => {
        clearNotificationTimeout(id);
        dispatch(generateNamedActionWithPayload(NOTIFICATION_REMOVE, id));
      },
      unmount: (id: string) => {
        dispatch(generateNamedActionWithPayload(NOTIFICATION_UNMOUNT, id));
      },
      clear: () => {
        clearAllNotificationTimeouts();
        dispatch(generateNamedAction(NOTIFICATION_CLEAR));
      },
    }),
    [clearAllNotificationTimeouts, clearNotificationTimeout, dispatch]
  );

  return actions;
}

interface NotificationUiActions {
  unmount: (id: string) => void;
}

export type NotificationUiState = NotificationUiActions & { notifications: NotificationState };

export function useNotificationUi(): NotificationUiState {
  const ctx = React.useContext(NotificationsContext);
  if (ctx === undefined) {
    throw new Error('useNotificationUi must be used within a NotificationsProvider.');
  }

  const { notifications, dispatch } = ctx;

  const unmount = React.useCallback(
    (id: string) => {
      dispatch(generateNamedActionWithPayload(NOTIFICATION_UNMOUNT, id));
    },
    [dispatch]
  );

  var value = React.useMemo(
    () => ({
      notifications,
      unmount,
    }),
    [notifications, unmount]
  );

  return value;
}

export interface NotificationsProviderProps {
  children?: React.ReactNode;
}

export function NotificationsProvider(props: NotificationsProviderProps) {
  const [currentState, dispatch] = React.useReducer(notificationsReducer, OrderedMap<string, Notification>());

  const value: NotificationsContextProps = React.useMemo(
    () => ({
      notifications: currentState,
      dispatch,
    }),
    [currentState]
  );

  const setNotificationTimeout = React.useCallback((notification: Notification) => {
    return window.setTimeout(
      () => dispatch(generateNamedActionWithPayload(NOTIFICATION_REMOVE, notification.id)),
      notification.timeExtantInMs ?? 0
    );
  }, []);

  // Subscribe to a global event to generate a notification
  React.useEffect(() => {
    function handleNotificationEvent(e: CustomEvent<NotificationActionPayload>) {
      dispatch(
        generateAddActionPayload({
          headerText: e.detail.headerText,
          message: e.detail.message,
          level: e.detail.level,
          actionButtonName: e.detail.actionButtonName,
          actionCallback: e.detail.actionCallback,
          timeoutInMs: e.detail.timeoutInMs ?? undefined,
        })
      );
    }

    window.addEventListener(DEFENSIX_NOTIFICATION_EVENT, handleNotificationEvent as EventListener);
    return () => window.removeEventListener(DEFENSIX_NOTIFICATION_EVENT, handleNotificationEvent as EventListener);
  }, []);

  React.useEffect(() => {
    currentState.forEach((notification) => {
      if (notification.timeExtantInMs && !notification.timeoutHandle) {
        let timeoutPayload = {
          id: notification.id,
          timeoutHandle: setNotificationTimeout(notification),
        };

        dispatch(generateNamedActionWithPayload(NOTIFICATION_SET_TIMEOUT, timeoutPayload));
      }
    });
  }, [currentState, setNotificationTimeout]);

  return <NotificationsContext.Provider value={value} {...props} />;
}

export function notify(
  headerText: string,
  message: string,
  level: NotificationLevel = NotificationLevel.Error,
  actionButtonName: string = '',
  actionCallback?: () => void
) {
  var event = new CustomEvent<NotificationActionPayload>(DEFENSIX_NOTIFICATION_EVENT, {
    detail: { headerText, message, level, actionButtonName, actionCallback },
  });
  window.dispatchEvent(event);
}

const DEFENSIX_NOTIFICATION_EVENT = 'defensix_notification';
const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';
const NOTIFICATION_UNMOUNT = 'NOTIFICATION_UNMOUNT';
const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';
const NOTIFICATION_SET_TIMEOUT = 'NOTIFICATION_SET_TIMEOUT';

type AddActionPayload = NamedActionPayload<'NOTIFICATION_ADD', NotificationActionPayload & Pick<Notification, 'id'>>;
type RemoveActionPayload = NamedActionPayload<'NOTIFICATION_REMOVE', string>;
type UnmountActionPayload = NamedActionPayload<'NOTIFICATION_UNMOUNT', string>;
type ClearActionPayload = NamedAction<'NOTIFICATION_CLEAR'>;
type SetTimeoutActionPayload = NamedActionPayload<'NOTIFICATION_SET_TIMEOUT', ExpireNotificationPayload>;

function generateAddActionPayload(notification: NotificationActionPayload): AddActionPayload {
  const payload = {
    ...notification,
    id: uniqueId(NOTIFICATION_ADD),
  };

  return generateNamedActionWithPayload(NOTIFICATION_ADD, payload);
}

type NotificationReducerActions =
  | AddActionPayload
  | RemoveActionPayload
  | UnmountActionPayload
  | ClearActionPayload
  | SetTimeoutActionPayload;

function notificationsReducer(state: NotificationState, action: NotificationReducerActions): NotificationState {
  let notifications = state;

  switch (action.type) {
    case NOTIFICATION_ADD: {
      const payload = action.payload;
      const id = payload.id;

      if (notifications.size === NOTIFICATIONS_LIMIT) {
        const firstNotification: Notification = notifications.first();
        notifications = notifications.delete(firstNotification.id);
      }

      notifications = notifications.set(id, {
        id: id,
        headerText: payload.headerText,
        message: payload.message,
        level: payload.level,
        actionButtonName: payload.actionButtonName,
        actionCallback: payload.actionCallback,
        timeExtantInMs: payload.timeoutInMs ?? null,
        timeoutHandle: null,
        visible: true,
      });
      break;
    }

    case NOTIFICATION_REMOVE: {
      const id: string = action.payload;

      notifications = notifications.setIn([id, 'visible'], false);
      break;
    }

    case NOTIFICATION_UNMOUNT: {
      const id: string = action.payload;
      notifications = notifications.remove(id);
      break;
    }

    case NOTIFICATION_CLEAR:
      notifications = notifications.withMutations((n) => {
        for (const i of n) {
          n = n.setIn([i[0], 'visible'], false);
        }
      });
      break;

    case NOTIFICATION_SET_TIMEOUT: {
      const { id, timeoutHandle } = action.payload;
      const notification = notifications.get(id);

      if (notification) {
        notifications = notifications.setIn([id, 'timeoutHandle'], timeoutHandle);
      }
      break;
    }

    default:
      return state;
  }

  return notifications;
}
