import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams as useSearchParamsLegacy, URLSearchParamsInit } from 'react-router-dom';
import { diffSearchParams, searchParamsSize, searchParamsToStrictlySorted } from '../util/url';

export type SearchParamsSetter = (
  nextInit: URLSearchParamsInit & SearchParamsConstructorInit,
  navigateOptions?:
    | {
        replace?: boolean | undefined;
        state?: any;
      }
    | undefined
) => void;

type SearchParamsConstructorInit = string[][] | Record<string, string> | string | URLSearchParams;

export function useSearchParamsDiff(defaultInit?: URLSearchParamsInit): {
  searchParams: URLSearchParams;
  oldSearchParams: URLSearchParams;
  setSearchParams: SearchParamsSetter;
  addedSearchParams: URLSearchParams;
  removedSearchParams: URLSearchParams;
} {
  const [searchParams, setSearchParamsLegacy] = useSearchParamsLegacy(defaultInit);
  const { state: locationState } = useLocation();
  const [oldSearchParams, saveOldSearchParams] = useState(new URLSearchParams());
  const { addedSearchParams, removedSearchParams } = useMemo(
    () =>
      diffSearchParams({
        oldSearchParams: oldSearchParams,
        newSearchParams: searchParams,
      }),
    [oldSearchParams, searchParams]
  );
  const setSearchParamsDelta = useCallback(
    (
      nextInit: URLSearchParamsInit & SearchParamsConstructorInit,
      navigateOptions?:
        | {
            replace?: boolean | undefined;
            state?: any;
          }
        | undefined
    ): void => {
      nextInit = searchParamsToStrictlySorted(new URLSearchParams(nextInit));

      if (JSON.stringify(navigateOptions?.state ?? null) !== JSON.stringify(locationState ?? null)) {
        return setSearchParamsLegacy(nextInit, navigateOptions);
      }

      const { addedSearchParams, removedSearchParams } = diffSearchParams({
        oldSearchParams: searchParams,
        newSearchParams: new URLSearchParams(nextInit),
      });

      // Update the search params state, only if its value has changed
      if (searchParamsSize(addedSearchParams) > 0 || searchParamsSize(removedSearchParams) > 0) {
        return setSearchParamsLegacy(nextInit, navigateOptions);
      }
    },
    [locationState, setSearchParamsLegacy, searchParams]
  );

  useEffect(() => {
    if (searchParamsSize(addedSearchParams) > 0 || searchParamsSize(removedSearchParams) > 0) {
      saveOldSearchParams(searchParams);
    }
  }, [addedSearchParams, removedSearchParams, searchParams, saveOldSearchParams]);

  return {
    searchParams: useMemo(() => Object.freeze(searchParams), [searchParams]),
    oldSearchParams: useMemo(() => Object.freeze(oldSearchParams), [oldSearchParams]),
    setSearchParams: useMemo(() => Object.freeze(setSearchParamsDelta), [setSearchParamsDelta]),
    addedSearchParams: useMemo(() => Object.freeze(addedSearchParams), [addedSearchParams]),
    removedSearchParams: useMemo(() => Object.freeze(removedSearchParams), [removedSearchParams]),
  };
}
