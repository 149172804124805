import React, { Reducer } from 'react';
import { CancelableRenderProps } from '../components/common';
import {
  NamedActionPayload,
  NamedAction,
  generateNamedAction,
  generateNamedActionWithPayload,
} from '../global/actions';
import { isObjectLike } from 'lodash';
import { isImmutable, merge } from 'immutable';

export type CancelableState<T = any> = CancelableRenderProps<T>;

interface State<T> {
  dirty: boolean;
  data: T;
  originalData: T;
  saving: boolean;
}

type Actions<T> =
  | NamedActionPayload<'init', T>
  | NamedActionPayload<'update', Partial<T>>
  | NamedAction<'save'>
  | NamedActionPayload<'saveSuccess', T>
  | NamedAction<'saveFailed'>
  | NamedAction<'cancel'>;

function reducer<T>(prev: State<T>, action: Actions<T>): State<T> {
  switch (action.type) {
    case 'init':
      return { data: action.payload, originalData: action.payload, dirty: false, saving: false };
    case 'update':
      return { ...prev, data: updateState(action.payload, prev.data), dirty: true };
    case 'cancel':
      return { ...prev, data: prev.originalData, dirty: false };
    case 'save':
      return { ...prev, saving: true };
    case 'saveSuccess':
      return { ...prev, originalData: action.payload, dirty: false, saving: false };
    case 'saveFailed':
      return { ...prev, saving: false };
  }

  return prev;
}

export type SaveCallback<T> = (data: T) => Promise<number> | Promise<void> | void;

export default function useCancelable<T = any>(saveFunc: SaveCallback<T>, initialState: T): CancelableState<T> {
  const [state, dispatch] = React.useReducer(
    reducer as Reducer<State<T>, Actions<T>>,
    { data: initialState, originalData: initialState, dirty: false, saving: false } as State<T>
  );

  React.useEffect(() => dispatch(initAction(initialState)), [initialState]);

  const handleSave = React.useCallback(async (): Promise<void> => {
    dispatch(saveAction());
    const savedData = state.data;
    const result = saveFunc(savedData);
    if (result === undefined) {
      dispatch(saveSuccessAction(savedData));
      return;
    }

    try {
      await (result as Promise<number | void>);
      dispatch(saveSuccessAction(savedData));
    } catch (err: unknown) {
      dispatch(saveFailedAction());
      throw err;
    }
  }, [saveFunc, state.data]);

  const handleCancel = React.useCallback(() => {
    dispatch(cancelAction());
  }, []);

  const handleUpdate = React.useCallback(function update(data: keyof T | Partial<T>, value?: any) {
    let updated: Partial<T>;
    if (typeof data === 'string' && typeof value !== 'undefined') {
      let obj: Partial<T> = {};
      obj[data] = value;
      updated = obj;
    } else {
      updated = data as Partial<T>;
    }

    dispatch(updateAction(updated));
    return Promise.resolve();
  }, []);

  const result = React.useMemo<CancelableState<T>>(
    () => ({
      ...state,
      save: handleSave,
      cancel: handleCancel,
      update: handleUpdate,
    }),
    [handleCancel, handleSave, handleUpdate, state]
  );

  return result;
}

function updateState<T>(updated: Partial<T>, prevState: T) {
  let newData: any;
  if (isObjectLike(updated)) {
    newData = isImmutable(updated) ? merge(prevState, updated) : Object.assign({}, prevState, updated);
  } else {
    newData = updated;
  }
  return newData;
}

const initAction = <T>(data: T) => generateNamedActionWithPayload('init', data);
const saveAction = () => generateNamedAction('save');
const saveSuccessAction = <T>(data: T) => generateNamedActionWithPayload('saveSuccess', data);
const saveFailedAction = () => generateNamedAction('saveFailed');
const cancelAction = () => generateNamedAction('cancel');
const updateAction = <T>(data: Partial<T>) => generateNamedActionWithPayload('update', data);
