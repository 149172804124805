const PhoneInHand: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-full" height="237.926" viewBox="0 0 242.394 237.926">
      <g id="_23" data-name="#23" transform="translate(-80.52 -34)">
        <path
          id="Path_1272"
          data-name="Path 1272"
          d="M127.78,232.41s10.84-25.59,13.67-35.59,2-39.68,2-39.68l7.84-20.5s55-44.68,71-54.34,35.34-24.68,41-21.34,4.67,10.67-2,18c-2.74,3-6.45,6.16-8.72,9.45-1.85,2.69-4.16,11.14-1.94,14.14,2.66,3.59,9.54-1.62,13.24,1.91,1.95,1.86,1.83,5.13.53,7.48s-3.51,4-5.54,5.81c-5.41,4.68-10,10.56-14.3,16.19-4.4,5.79-1.88,14-1.53,20.67.42,8.11,1.55,17.78-.74,25.71-3.67,12.66-25.18,28.33-34.51,36.34s-17.17,34.67-17.17,34.67Z"
          fill="#fff"
        />
        <path
          id="Path_1273"
          data-name="Path 1273"
          d="M190.63,251.83h-.15l-62.84-18.92a.49.49,0,0,1-.31-.27.53.53,0,0,1,0-.41c.11-.25,10.86-25.67,13.65-35.53s2-39.23,2-39.52a.58.58,0,0,1,0-.19l7.84-20.51a.51.51,0,0,1,.15-.21c.55-.45,55.22-44.81,71.07-54.38,4-2.43,8.29-5.22,12.42-7.92,13.2-8.63,24.59-16.07,29.1-13.42A7.58,7.58,0,0,1,267.41,66c.58,3.86-1.45,8.57-5.73,13.28-1,1-2,2.09-3.11,3.19a48,48,0,0,0-5.57,6.2c-1.85,2.69-4,10.84-1.95,13.56,1.14,1.54,3.26,1.19,5.7.79s5.45-.89,7.49,1.06,2,5.64.61,8.08a19.769,19.769,0,0,1-4.87,5.28l-.78.67c-5.35,4.63-10,10.5-14.22,16.11-3.27,4.29-2.58,10-1.91,15.51.2,1.69.39,3.28.48,4.84.06,1.25.14,2.54.23,3.86.45,7.13,1,15.22-1,22-3.1,10.76-18.78,23.57-29.22,32.1-2,1.64-3.91,3.2-5.44,4.51-9.11,7.81-16.93,34.17-17,34.43a.52.52,0,0,1-.24.3.53.53,0,0,1-.25.06Zm-62.17-19.74,61.83,18.62c1.14-3.76,8.42-26.93,17.18-34.43,1.54-1.32,3.45-2.88,5.46-4.53,10.3-8.41,25.87-21.13,28.89-31.57,1.92-6.62,1.41-14.61,1-21.67-.08-1.32-.17-2.61-.23-3.87-.08-1.52-.27-3.1-.47-4.77-.66-5.45-1.41-11.62,2.1-16.23,4.3-5.66,8.94-11.57,14.37-16.26l.78-.68a19.17,19.17,0,0,0,4.65-5c1.16-2.11,1.39-5.14-.43-6.88s-4.2-1.19-6.63-.79-5.17.84-6.67-1.19c-2.46-3.31.08-12,1.93-14.71a47,47,0,0,1,5.69-6.35c1.09-1.09,2.13-2.12,3.07-3.16,4-4.41,6-9,5.48-12.45a6.52,6.52,0,0,0-3.36-4.78c-4-2.34-16.22,5.66-28.06,13.4-4.13,2.7-8.41,5.5-12.44,7.93-15.46,9.34-68.15,52-70.85,54.22L144,157.23c.06,2.21.75,30-2,39.73C139.34,206.09,130,228.42,128.46,232.09Z"
          fill="#231f20"
        />
        <rect
          id="Rectangle_2476"
          data-name="Rectangle 2476"
          width="80.23"
          height="159.92"
          rx="5.11"
          transform="translate(175.79 34.45)"
          fill="#fff"
        />
        <path
          id="Path_1274"
          data-name="Path 1274"
          d="M250.91,194.88h-70a5.62,5.62,0,0,1-5.62-5.62V39.57A5.62,5.62,0,0,1,180.91,34h70a5.62,5.62,0,0,1,5.61,5.62V189.26a5.62,5.62,0,0,1-5.61,5.62ZM180.91,35a4.62,4.62,0,0,0-4.62,4.62V189.26a4.62,4.62,0,0,0,4.62,4.62h70a4.62,4.62,0,0,0,4.61-4.62V39.57A4.62,4.62,0,0,0,250.91,35Z"
          fill="#231f20"
        />
        <rect
          id="Rectangle_2477"
          data-name="Rectangle 2477"
          width="71.8"
          height="123.08"
          transform="translate(180.17 48.92)"
          fill="#00b3dd"
        />
        <path
          id="Path_1275"
          data-name="Path 1275"
          d="M223.57,190H209.91a3.64,3.64,0,0,1-3.64-3.64v-5.22a3.65,3.65,0,0,1,3.64-3.65h13.66a3.65,3.65,0,0,1,3.64,3.65v5.22a3.64,3.64,0,0,1-3.64,3.64Zm-13.66-11.51a2.65,2.65,0,0,0-2.64,2.65v5.22a2.64,2.64,0,0,0,2.64,2.64h13.66a2.64,2.64,0,0,0,2.64-2.64v-5.22a2.65,2.65,0,0,0-2.64-2.65Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1276"
          data-name="Path 1276"
          d="M221.89,42.12H201.45a.5.5,0,0,1,0-1h20.44a.5.5,0,1,1,0,1Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1277"
          data-name="Path 1277"
          d="M227.5,41.88h-2.74a.5.5,0,1,1,0-1h2.74a.5.5,0,0,1,0,1Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1278"
          data-name="Path 1278"
          d="M248.33,104.47A8.76,8.76,0,0,0,258.15,115a9.88,9.88,0,0,0,4.42-1.87,10.22,10.22,0,0,0,4.18-7.46c.28-4.81-3.78-9-8.5-9.58C253.83,95.54,249.2,100.44,248.33,104.47Z"
          fill="#fff"
        />
        <path
          id="Path_1279"
          data-name="Path 1279"
          d="M256.94,115.58a9.27,9.27,0,0,1-9.1-11.21c1-4.45,5.84-9.36,10.47-8.79,5.21.64,9.23,5.18,8.94,10.11a10.69,10.69,0,0,1-4.38,7.83,10.47,10.47,0,0,1-4.65,2A9.069,9.069,0,0,1,256.94,115.58Zm.63-19c-3.84,0-7.93,4.23-8.75,8h0a8.27,8.27,0,0,0,9.26,9.92,9.41,9.41,0,0,0,4.2-1.78,9.69,9.69,0,0,0,4-7.09c.25-4.34-3.44-8.49-8.06-9.06-.22-.02-.44-.03-.65-.03Zm-9.24,7.93Z"
          fill="#231f20"
        />
        <path
          id="Path_1280"
          data-name="Path 1280"
          d="M245.21,135.65a6.58,6.58,0,0,0-.92,5.67,7.82,7.82,0,0,0,2.09,2.91c2.41,2.28,5.7,3.89,9,3.4a13.55,13.55,0,0,0,5.51-2.52c2.9-2,5.68-4.52,6.64-7.89,1.39-4.89-2.15-12-7.88-11.32C254.91,126.49,247.72,131.76,245.21,135.65Z"
          fill="#fff"
        />
        <path
          id="Path_1281"
          data-name="Path 1281"
          d="M254.16,148.22A12.09,12.09,0,0,1,246,144.6a8.22,8.22,0,0,1-2.22-3.11,7.06,7.06,0,0,1,1-6.11h0c2.57-4,9.89-9.36,14.76-10a6.93,6.93,0,0,1,6.22,2.57,10.7,10.7,0,0,1,2.21,9.37c-.84,3-3,5.55-6.83,8.17a14.07,14.07,0,0,1-5.73,2.6,8.8,8.8,0,0,1-1.25.13Zm-8.53-12.3a6.06,6.06,0,0,0-.87,5.23,7.25,7.25,0,0,0,2,2.72c2.63,2.49,5.74,3.68,8.55,3.26a13.28,13.28,0,0,0,5.3-2.43c3.62-2.49,5.67-4.91,6.44-7.62a9.68,9.68,0,0,0-2-8.45,6,6,0,0,0-5.33-2.23c-4.55.57-11.64,5.8-14.05,9.52Z"
          fill="#231f20"
        />
        <path
          id="Path_1282"
          data-name="Path 1282"
          d="M248.12,156.06a9.24,9.24,0,0,0-2.73,2.94,8,8,0,0,0,.9,8.51,11.36,11.36,0,0,0,7.8,4.09,12.69,12.69,0,0,0,9.91-3.07,10.32,10.32,0,0,0,3.16-9.71C265,149.64,253.84,151.9,248.12,156.06Z"
          fill="#fff"
        />
        <path
          id="Path_1283"
          data-name="Path 1283"
          d="M255.54,172.18a9.618,9.618,0,0,1-1.51-.08,11.8,11.8,0,0,1-8.14-4.29,8.54,8.54,0,0,1-.93-9.05,9.66,9.66,0,0,1,2.87-3.1h0c3.86-2.81,10.14-4.78,14.66-3.1a8.18,8.18,0,0,1,5.15,6.15,10.73,10.73,0,0,1-3.3,10.19A13.06,13.06,0,0,1,255.54,172.18Zm-7.12-15.71a8.81,8.81,0,0,0-2.59,2.77,7.54,7.54,0,0,0,.85,8,10.83,10.83,0,0,0,7.46,3.9,12.23,12.23,0,0,0,9.53-2.94,9.71,9.71,0,0,0,3-9.22,7.16,7.16,0,0,0-4.52-5.45c-4.2-1.55-10.09.33-13.73,3Z"
          fill="#231f20"
        />
        <path
          id="Path_1284"
          data-name="Path 1284"
          d="M130.95,208.4l7.17-14.16,67.84,27.17-7.66,12.33Z"
          fill="#00b3dd"
        />
        <path
          id="Path_1285"
          data-name="Path 1285"
          d="M203.8,229.66l-73.52-28.01-25,39.26,80.51,30.51Z"
          fill="#231f20"
        />
        <path
          id="Path_1286"
          data-name="Path 1286"
          d="M185.79,271.92a.591.591,0,0,1-.17,0l-80.52-30.5a.49.49,0,0,1-.29-.31.5.5,0,0,1,0-.43l25-39.25a.5.5,0,0,1,.6-.2l73.51,28a.55.55,0,0,1,.29.27.51.51,0,0,1,0,.4l-18,41.75a.5.5,0,0,1-.42.27ZM106,240.66l79.5,30.12,17.6-40.84-72.65-27.68Z"
          fill="#231f20"
        />
        <path
          id="Path_1287"
          data-name="Path 1287"
          d="M143.59,158s.5-42.88,5.5-51.39,20.78-44.26,25-44.51,8.62,12.69,4.91,24.2a69.57,69.57,0,0,1-9.5,19s12.42,13.25,5.66,37"
          fill="#fff"
        />
        <path
          id="Path_1288"
          data-name="Path 1288"
          d="M143.59,158.52h0a.5.5,0,0,1-.49-.51c0-1.75.56-43.11,5.57-51.63,1.52-2.6,4.06-7.74,7-13.71,8.9-18.06,15.43-30.87,18.44-31,1.1-.08,2.23.59,3.25,1.91,3.23,4.18,5,14.14,2.08,22.91a72.24,72.24,0,0,1-9.33,18.83c1.8,2.17,11.69,15.41,5.51,37.16a.518.518,0,0,1-1-.27c3.45-12.12,1.76-21.4-.27-27a29.62,29.62,0,0,0-5.28-9.48.5.5,0,0,1,0-.64,69.64,69.64,0,0,0,9.42-18.86c2.76-8.46,1.15-18-1.92-22a3.21,3.21,0,0,0-2.41-1.53c-2.65.16-12,19.15-17.6,30.5-2.94,6-5.49,11.14-7,13.77-4.88,8.29-5.43,50.71-5.43,51.13a.51.51,0,0,1-.54.42Z"
          fill="#231f20"
        />
        <circle
          id="Ellipse_346"
          data-name="Ellipse 346"
          cx="5.25"
          cy="5.25"
          r="5.25"
          transform="translate(125.28 224.91)"
          fill="#fff"
        />
        <path
          id="Path_1289"
          data-name="Path 1289"
          d="M129,105.16a.45.45,0,0,1-.27-.08L102.42,87.89a.5.5,0,0,1,.55-.84l26.32,17.19a.51.51,0,0,1,.15.7.52.52,0,0,1-.44.22Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1290"
          data-name="Path 1290"
          d="M117.94,138.07h0L81,136.36a.5.5,0,0,1-.48-.52.52.52,0,0,1,.52-.48L118,137.07a.52.52,0,0,1,.48.53A.51.51,0,0,1,117.94,138.07Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1291"
          data-name="Path 1291"
          d="M296.28,101.33a.52.52,0,0,1-.47-.33.5.5,0,0,1,.3-.64l21.71-8a.5.5,0,0,1,.64.3.49.49,0,0,1-.29.64l-21.72,8a.47.47,0,0,1-.17.03Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1292"
          data-name="Path 1292"
          d="M322.41,157.37h-.12l-24.06-5.86a.5.5,0,1,1,.24-.97l24.06,5.86a.491.491,0,0,1-.12.97Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1293"
          data-name="Path 1293"
          d="M284.28,227.94a.52.52,0,0,1-.38-.17l-14.07-16.42a.49.49,0,0,1,.05-.7.51.51,0,0,1,.71,0l14.07,16.42a.49.49,0,0,1-.05.7.52.52,0,0,1-.33.17Z"
          fill="#d1d3d4"
        />
        <path
          id="Path_1294"
          data-name="Path 1294"
          d="M108.47,182a.51.51,0,0,1-.49-.37.5.5,0,0,1,.36-.61l13.58-3.6a.5.5,0,0,1,.26.97l-13.58,3.6Z"
          fill="#d1d3d4"
        />
      </g>
    </svg>
  );
};

export default PhoneInHand;
