import { List } from 'immutable';
import { EmptyListPlaceholderProps, ListFilters, ListTable } from '.';
import { ListPageState, UseListPageStateActions } from '../../hooks/useListPageHelpers';
import AllErrorsAlert from './errors/AllErrorsAlert';
import { ErrorMapType } from './errors/renderErrors';
import { ListFilterType } from './ListFilters';
import { ListTableCol } from './ListTable';
import PageTemplate, { PageTemplateProps } from './PageTemplate';

export interface ListPageDisplayProps<T, F> extends ListState<T, F> {
  filters: F;
  list: List<T>;
  pageTemplateProps: Omit<PageTemplateProps, 'loading'>;
  filterInfo: ListFilterType<F>;
  defaultFilters: F;
  colInfo: ListTableCol<T>;
  errors?: ErrorMapType;
  emptyProps: EmptyListPlaceholderProps;
}
export type ListState<T, F> = ListPageState<T, F> & UseListPageStateActions<F>;

export default function ListPageDisplay<T, F>({
  pageTemplateProps,
  errors,
  resetFilters,
  changeFilter,
  defaultFilters,
  filterInfo,
  filters,
  colInfo,
  list,
  sortInfo,
  pageInfo,
  changeSort,
  changePage,
  emptyProps,
  loading,
  loadingList,
}: ListPageDisplayProps<T, F>) {
  return (
    <PageTemplate {...pageTemplateProps} loading={loading}>
      <>
        {errors && <AllErrorsAlert errors={errors} />}
        <div className="drop-shadow">
          <div className="relative z-30">
            <ListFilters<F>
              resetFilters={resetFilters}
              changeFilter={changeFilter}
              defaultFilters={defaultFilters}
              filterInfo={filterInfo}
              filters={filters}
            />
          </div>
          <div className="relative z-20">
            <ListTable
              loading={loadingList}
              colInfo={colInfo}
              list={list}
              sortInfo={sortInfo}
              pageInfo={pageInfo}
              changeSort={changeSort}
              changePage={changePage}
              emptyProps={emptyProps}
            />
          </div>
        </div>
      </>
    </PageTemplate>
  );
}
