import axios, { AxiosInstance, CancelToken } from 'axios';
import * as React from 'react';
import createHttp from '../util/http';

export default function useHttp(
  handleErrors: boolean = false,
  excludeCodes: Array<number> = []
): [AxiosInstance, CancelToken] {
  const cancelToken = React.useRef(axios.CancelToken.source()).current;
  const http = React.useRef(createHttp(handleErrors, excludeCodes)).current;

  React.useEffect(() => {
    return cancelToken.cancel;
  }, [cancelToken.cancel]);

  return [http, cancelToken.token];
}

export { httpStatusClass, dispatchClientError } from '../util/http';
