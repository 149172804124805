import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApiBase } from '../apiBase';
import { PageInfoRecord, SortDirection, SortInfoRecord } from '../components/common';
import { useApiMethod } from './useApi';
import { useIndexStorage } from './useIndexStorage';
import {
  getInitialListPageState,
  ListPageReducer,
  ListPageReducerAction,
  ListPageState,
  ListResponse,
  MandatoryInitialState,
  OptionalInitialPage,
} from './useListPageHelpers';
import { reject } from 'lodash';

type ListFunction<TData, TFilters, TParams = {}> = (
  pageInfo: PageInfoRecord,
  sortInfo: SortInfoRecord,
  filterInfo: TFilters,
  pathParams: TParams
) => Promise<ListResponse<TData>>;

type FunctionPropertyNames<TApi, TData, TFilters, TParams> =
  | FunctionPropertyNamesWithoutParams<TApi, TData, TFilters>
  | FunctionPropertyNamesWithParams<TApi, TData, TFilters, TParams>;

type FunctionPropertyNamesWithoutParams<TApi, TData, TFilters> = {
  [K in keyof TApi]: TApi[K] extends ListFunction<TData, TFilters> ? K : never;
}[keyof TApi];

type FunctionPropertyNamesWithParams<TApi, TData, TFilters, TParams> = {
  [K in keyof TApi]: TApi[K] extends ListFunction<TData, TFilters, TParams> ? K : never;
}[keyof TApi];

const useListPageState = <TData>(transientPaging: boolean = false) => {
  const useListPageState = <TFilters, TApi extends ApiBase, TDataType = TData, TParams = {}>(
    apiClass: Clazz<TApi>,
    initialState: MandatoryInitialState<TFilters & OptionalInitialPage>,
    listRetrievalMethod: FunctionPropertyNames<TApi, TDataType, TFilters, TParams>,
    pathParams?: TParams
  ) => {
    type TState = ListPageState<TDataType, TFilters>;
    const { indexStates, setIndexStates } = useIndexStorage();
    const indexStatesRef = React.useRef(indexStates);
    const setIndexStatesRef = React.useRef(setIndexStates);
    let initialStateWithStorage = React.useRef<MandatoryInitialState<TFilters & OptionalInitialPage>>(initialState);

    const [state, dispatch] = React.useReducer((s: TState, a: ListPageReducerAction<TDataType, TFilters>) => {
      let newState = ListPageReducer<TDataType, TFilters, TState>(s, a);
      return newState;
    }, getInitialListPageState<TDataType, TFilters & OptionalInitialPage>(initialStateWithStorage.current));
    const [isFilterRetrievalComplete, setIsFilterRetrievalComplete] = React.useState(false);
    type TFilterKey = keyof TFilters;

    React.useEffect(() => {
      indexStatesRef.current = indexStates;
    }, [indexStates]);

    React.useEffect(() => {
      setIndexStatesRef.current = setIndexStates;
    }, [setIndexStates]);

    const location = useLocation();
    const pathKeyRef = React.useRef(normalizePath(location.pathname));

    React.useEffect(() => {
      const normalizedPath: string = normalizePath(location.pathname);

      if (pathKeyRef.current !== location.pathname) {
        if (transientPaging) {
          dispatch({ type: 'removeFilter', payload: 'maxId' as keyof TFilters });
          dispatch({ type: 'changePage', payload: 1 });
        }

        pathKeyRef.current = normalizedPath;
      }
    }, [location.pathname, dispatch]);

    React.useEffect(() => {
      if (!transientPaging) {
        const pageSavedState = indexStatesRef.current.get(pathKeyRef.current);

        if (pageSavedState) {
          dispatch({
            type: 'setInitialState',
            payload: { filters: pageSavedState.filters, page: pageSavedState.page, sortInfo: pageSavedState.sort },
          });
        }
      }

      setIsFilterRetrievalComplete(true);
    }, []);

    useApiMethod(
      apiClass,
      (api) => {
        // The typings here aren't translating correctly, but listRetrievalMethod should be
        // the set of property names from TApi that match the ListFunction<TDataType> signature,
        // so we should have fairly strong type safety for consumers of this hook.
        const func: ListFunction<TDataType, TFilters, TParams> = api[listRetrievalMethod] as any;
        if (isFilterRetrievalComplete) {
          dispatch({ type: 'loadingList' });

          return func(
            state.pageInfo.set('page', state.page),
            state.sortInfo,
            state.filters,
            pathParams ?? ({} as TParams)
          )
            .then((data) => {
              dispatch({
                type: 'loadedList',
                payload: data,
              });
            })
            .catch((error) => {
              if (error.message !== 'REQUEST_CANCELED') {
                dispatch({ type: 'erroredList' });
                reject(error);
              }
            });
        } else {
          return Promise.resolve();
        }
      },
      [state.page, state.sortInfo, state.reloadIteration, state.filters, isFilterRetrievalComplete, pathParams]
    );

    React.useEffect(() => {
      setIndexStatesRef.current(pathKeyRef.current, { filters: state.filters, page: state.page, sort: state.sortInfo });
    }, [state.filters, state.page, state.sortInfo]);

    const changePage = React.useCallback((pageNumber: number) => {
      dispatch({ type: 'changePage', payload: pageNumber });
    }, []);
    const changeSort = React.useCallback((sortInfo: { column: string; direction: SortDirection }) => {
      dispatch({ type: 'changeSort', payload: sortInfo });
    }, []);
    const changeFilter = React.useCallback((filterInfo: { filterName: TFilterKey; value: TFilters[TFilterKey] }) => {
      dispatch({ type: 'changeFilter', payload: { filterName: filterInfo.filterName, value: filterInfo.value } });
    }, []);
    const removeFilter = React.useCallback((filterName: TFilterKey) => {
      dispatch({ type: 'removeFilter', payload: filterName });
    }, []);
    const resetFilters = React.useCallback((filterInfo: TFilters) => {
      dispatch({ type: 'resetFilters', payload: filterInfo });
    }, []);
    const reloadList = React.useCallback(() => {
      dispatch({ type: 'reloadList' });
    }, []);

    const actions = React.useMemo(
      () => ({
        changePage: changePage,
        changeSort: changeSort,
        changeFilter: changeFilter,
        removeFilter: removeFilter,
        resetFilters: resetFilters,
        reloadList: reloadList,
      }),
      [changePage, changeSort, changeFilter, removeFilter, resetFilters, reloadList]
    );

    return {
      state: state,
      actions: actions,
    };
  };

  return useListPageState;
};
export default useListPageState;

interface Clazz<T> {
  new (): T;
}

const normalizePath = (path: string) => {
  if (path.endsWith('/')) {
    return path.substr(0, path.length - 1);
  } else {
    return path;
  }
};
