export * from 'url';

export class SearchObject {
  [k: string]: string | string[];
}

export function createSearchObject(params: any): any {
  let searchObject = new SearchObject();

  if (params instanceof URLSearchParams) {
    for (let name of params.keys()) {
      let values = params.getAll(name);

      if (values.length > 1) {
        if (name.length > 2 && name.substring(name.length - 2) === '[]') {
          name = name.substring(0, name.length - 2);
        }

        searchObject[name] = values;
      } else {
        searchObject[name] = values[0];
      }
    }

    return searchObject;
  }

  for (const name of Object.keys(params)) {
    const value = params[name];

    if (isValidSearchParamValue(value)) {
      searchObject[name] = String(value);
    }

    if (value instanceof Array) {
      const entries: string[] = [];

      for (const entry of value) {
        if (isValidSearchParamValue(entry)) {
          entries.push(String(entry));
        }
      }

      searchObject[name] = entries;
    }
  }

  return searchObject;
}

function isValidSearchParamValue(value: any): boolean {
  let type = typeof value;

  return (
    type === 'string' ||
    value instanceof String ||
    type === 'number' ||
    type === 'bigint' ||
    value instanceof Number ||
    type === 'boolean' ||
    value instanceof Boolean
  );
}

export function createSearchParams(params: any): URLSearchParams {
  let searchParams = new URLSearchParams();
  const entries: [string, any][] =
    params instanceof URLSearchParams ? Array.from(params.entries()) : Object.entries(params);

  for (const [name, value] of entries) {
    const values: string[] = Array.isArray(value) ? value : [value];

    for (const value of values) {
      if (isValidSearchParamValue(value)) {
        searchParams.append(name, value);
      }
    }
  }

  return searchParams;
}

type CompareResult = -1 | 0 | 1;

export function diffSearchParams(states: { oldSearchParams: URLSearchParams; newSearchParams: URLSearchParams }): {
  addedSearchParams: URLSearchParams;
  removedSearchParams: URLSearchParams;
} {
  // Normalize order of params
  const oldParams: [string, string][] = searchParamsToSortedTuples(states.oldSearchParams);
  const newParams: [string, string][] = searchParamsToSortedTuples(states.newSearchParams);
  const addedParams: [string, string][] = [];
  const removedParams: [string, string][] = [];
  let oldIndex = 0;
  let newIndex = 0;

  while (oldIndex < oldParams.length && newIndex < newParams.length) {
    const result: CompareResult = compareParamsTuple(oldParams[oldIndex], newParams[newIndex]);

    // Left entry is comparatively less
    if (result < 0) {
      removedParams.push(oldParams[oldIndex++]);
    }

    // Left entry is comparatively greater
    else if (result > 0) {
      addedParams.push(newParams[newIndex++]);
    }

    // Left and right entries are comparatively equal
    else {
      oldIndex++;
      newIndex++;
    }
  }

  while (oldIndex < oldParams.length) {
    removedParams.push(oldParams[oldIndex++]);
  }

  while (newIndex < newParams.length) {
    addedParams.push(newParams[newIndex++]);
  }

  return {
    addedSearchParams: Object.freeze(new URLSearchParams(addedParams)),
    removedSearchParams: Object.freeze(new URLSearchParams(removedParams)),
  };
}

// Private function
function searchParamsToSortedTuples(searchParams: URLSearchParams): [string, string][] {
  return Array.from(searchParams.entries()).sort(compareParamsTuple);
}

function compareParamsTuple(left: [string, string], right: [string, string]): CompareResult {
  let result: CompareResult = left[0].localeCompare(right[0]) as CompareResult;

  if (result !== 0) {
    return result;
  }

  result = left[1].localeCompare(right[1]) as CompareResult;

  return result;
}

export function searchParamsSize(searchParams: URLSearchParams): number {
  return Array.from(searchParams.entries()).length;
}

export function isSearchParamsTuplePresent(searchParams: URLSearchParams, [name, value]: [string, string]): boolean {
  return searchParams.has(name) && searchParams.getAll(name).findIndex((entry) => entry === value) !== -1;
}

export function searchParamsToStrictlySorted(searchParams: URLSearchParams): URLSearchParams {
  return new URLSearchParams(searchParamsToSortedTuples(searchParams));
}

export function isSearchParamsEqualTo(left: URLSearchParams, right: URLSearchParams): boolean {
  const { addedSearchParams, removedSearchParams } = diffSearchParams({
    oldSearchParams: left,
    newSearchParams: right,
  });

  return searchParamsSize(addedSearchParams) === 0 && searchParamsSize(removedSearchParams) === 0;
}
