import { List } from 'immutable';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { EmptyListPlaceholderProps, getNavigateParams, mergeClasses, SortDirection, SortHeaderButton } from '.';
import { formatCamelCaseAsWords } from '../../util/formattingHelpers';
import { LoadingIndicator } from '../common';
import { EmptyListPlaceholder, ListPagination, NavigateParams, PageInfoRecord, SortInfoRecord } from './';

export interface ListTableProps<T> {
  colInfo: ListTableCol<T>;
  list: List<T>;
  sortInfo: SortInfoRecord;
  pageInfo: PageInfoRecord;
  changeSort(sortInfo: { column: string; direction: SortDirection }): void;
  changePage(pageNumber: number): void;
  emptyProps: EmptyListPlaceholderProps;
  loading?: boolean;
}

export type ListTableCol<T> = { [K in keyof T]?: ColInfo<T> } | { actions?: ColInfo<T> };
interface ColInfo<T> {
  title?: string;
  sortId?: string;
  displayFunc?(item: T): React.ReactNode;
  linkFunc?(item: T): NavigateParams | string;
  applyLinkFuncToRow?: boolean;
  headerAlign?: 'left' | 'center' | 'right';
  headerClassName?: string;
  className?: string;
}

export default function ListTable<T>(props: ListTableProps<T>) {
  let keys = Object.keys(props.colInfo).map((k) => k as keyof T);
  return !!props.loading ? (
    <LoadingIndicator className="mt-6" />
  ) : (
    <div className="flex flex-col w-full">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="z-20 border-b border-t border-gray-200 sm:rounded-b">
            <table className="divide-y divide-gray-200 w-full">
              <thead className="relative z-20 bg-gray-200">
                <tr>
                  {keys.map((k) => {
                    const col = (props.colInfo as any)[k]!;
                    const name = col.title ? col.title : col.title === '' ? '' : formatCamelCaseAsWords(k as string);
                    return (
                      <th
                        key={k.toString()}
                        scope="col"
                        className={mergeClasses('px-4 text-gray-500', col.headerClassName)}
                        style={{ width: col.width }}
                      >
                        {k === 'actions' ? null : (
                          <div className="py-1">
                            <SortHeaderButton
                              name={col.sortId ? col.sortId : name}
                              disabled={!col.sortId}
                              sortInfo={props.sortInfo}
                              onSort={(column, direction) => props.changeSort({ column: column, direction: direction })}
                              align={col.headerAlign}
                            >
                              {name}
                            </SortHeaderButton>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {props.list.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className={i % 2 === 0 ? 'relative max-h-10 bg-white' : 'relative max-h-10 bg-gray-200'}
                    >
                      {keys.map((k) => {
                        const col: ColInfo<T> = (props.colInfo as any)[k]!;
                        const display = col.displayFunc ? col.displayFunc(item) : item[k];
                        const navigateParams =
                          col.linkFunc === undefined ? undefined : getNavigateParams(col.linkFunc(item));

                        return (
                          <td
                            key={k.toString()}
                            className={mergeClasses(
                              'px-6 py-2 text-sm font-medium',
                              k === 'actions' ? 'whitespace-nowrap ' : null,
                              col.className
                            )}
                          >
                            {navigateParams ? (
                              <Link
                                className={col.applyLinkFuncToRow ? 'after:absolute after:inset-0' : ''}
                                {...navigateParams}
                              >
                                {display}
                              </Link>
                            ) : (
                              display
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {props.list.size === 0 && (
              <EmptyListPlaceholder {...props.emptyProps} className="rounded-none border-none" />
            )}
            <ListPagination pageInfo={props.pageInfo} onSelect={props.changePage} />
          </div>
        </div>
      </div>
    </div>
  );
}
