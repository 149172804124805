import React, { useCallback, useEffect } from 'react';
import { useUserProfile } from '../global/UserCompanyContext';

type FreshworksWidgetCommands =
  | 'show'
  | 'hide'
  | 'open'
  | 'close'
  | 'boot'
  | 'destroy'
  | 'identify'
  | 'prefill'
  | 'disable'
  | 'clear';
interface FreshworksWidget {
  (command: FreshworksWidgetCommands, ...args: any[]): void;
  q?: any[];
}
interface FreshworksWidgetSettings {
  widget_id: number;
}

declare global {
  interface Window {
    FreshworksWidget?: FreshworksWidget;
    fwSettings?: FreshworksWidgetSettings;
  }
}

export function useFreshworksWidget() {
  const { email, firstName, lastName } = useUserProfile();

  useEffect(() => {
    if (email === '') {
      return;
    }

    if ('function' !== typeof window.FreshworksWidget) {
      // Initialize Freshworks pre-load proxy and settings
      const preloadProxy: FreshworksWidget = function () {
        preloadProxy.q!.push(arguments);
      };
      preloadProxy.q = [];
      window.FreshworksWidget = preloadProxy;

      window.fwSettings = { widget_id: 64000001649 };

      // Load script
      const script = document.createElement('script');
      script.src = 'https://widget.freshworks.com/widgets/64000001649.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    } else {
      window.FreshworksWidget('boot');
    }

    window.FreshworksWidget('hide', 'launcher');

    return () => {
      window.FreshworksWidget!('destroy');
    };
  }, [email]);

  useEffect(() => {
    const FreshworksWidget = window.FreshworksWidget;
    if (FreshworksWidget === undefined) {
      return;
    }

    FreshworksWidget('identify', 'ticketForm', {
      name: `${firstName} ${lastName}`,
      email: email,
    });
  }, [email, firstName, lastName]);
}

export function useOpenFreshworksWidget() {
  return useCallback((e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    const FreshworksWidget = window.FreshworksWidget!;
    FreshworksWidget('open', 'ticketForm');
  }, []);
}
