import * as React from 'react';
import { Button, ButtonProps } from '.';

export type LinkButtonProps = ButtonProps;

// TODO: In reality we could get rid of this and just use <Button /> everywhere
function LinkButton(props: LinkButtonProps) {
  return <Button {...props} />;
}

export default LinkButton;
