const vowels = ['A', 'E', 'I', 'O', 'U'];
export function formatIndefiniteArticle(noun: string): string {
  if (noun.length === 0) {
    return 'a';
  }

  return vowels.indexOf(noun[0].toUpperCase()) === -1 ? 'a' : 'an';
}

export function formatBooleanAsYesNo(bool: boolean): string {
  return bool ? 'Yes' : 'No';
}

export function formatCamelCaseAsWords(camelCase: string): string {
  return Array.from(camelCase).reduce((prev, cur) => {
    if (prev.length === 0) {
      return cur.toUpperCase();
    }

    if (isUpperCase(cur) && !isUpperCase(prev[prev.length - 1])) {
      return prev + ' ' + cur;
    }

    return prev + cur;
  }, '');
}

export function isUpperCase(c: string) {
  return c === c.toUpperCase();
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export enum UIDateFormat {
  MonthYear,
  MonthDateYear,
  LongDate,
  LongDateTime,
  YYYYMMDD,
  Date,
  DateTime,
  DateNonUTC,
  DateTimeNonUTC,
  DateForInput,
  RelativeTimeUnder24Hours,
}

export type UIDateFormatter = (date: Date) => string | undefined | null;

export function UIDateFormatFactory(format: UIDateFormat, options?: Intl.DateTimeFormatOptions): UIDateFormatter {
  switch (format) {
    case UIDateFormat.MonthYear:
      return formatMonthYear;

    case UIDateFormat.MonthDateYear:
      return formatMonthDateYear;

    case UIDateFormat.LongDate:
      return options ? (date: Date) => formatLongDate(date, options) : formatLongDate;

    case UIDateFormat.LongDateTime:
      return options ? (date: Date) => formatLongDateTime(date, options) : formatLongDateTime;

    case UIDateFormat.YYYYMMDD:
      return formatDateToYYYYMMDD;

    case UIDateFormat.Date:
      return formatDate;

    case UIDateFormat.DateTime:
      return formatDateTime;

    case UIDateFormat.DateNonUTC:
      return formatDateNonUTC;

    case UIDateFormat.DateTimeNonUTC:
      return formatDateTimeNonUTC;

    case UIDateFormat.DateForInput:
      return formatDateForInput;

    case UIDateFormat.RelativeTimeUnder24Hours:
      return (date: Date) => formatRelativeTimeUnder24Hours(date, new Date());

    default:
      return formatDateTime;
  }
}

export function formatMonthYear(date: Date): string {
  return monthNames[date.getMonth()] + ' ' + date.getFullYear();
}

export function formatMonthDateYear(date: Date): string {
  return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

const DefaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'long',
};

export function formatLongDate(date: Date, options?: Intl.DateTimeFormatOptions): string {
  return new Intl.DateTimeFormat(undefined, options ?? DefaultDateFormatOptions).format(date);
}

const DefaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'long',
  timeStyle: 'long',
};

export function formatLongDateTime(date: Date, options?: Intl.DateTimeFormatOptions): string {
  return new Intl.DateTimeFormat(undefined, options ?? DefaultDateTimeFormatOptions).format(date);
}

export function formatDateToYYYYMMDD(date: Date): string {
  return transformToUtc(date).toISOString();
}
export function formatDateRange(from?: Date | string, to?: Date | string) {
  const fromDate = from instanceof Date ? from : new Date(from as string);
  const toDate = to instanceof Date ? to : new Date(to as string);
  if (from && to) {
    return formatDateInternalUTC(fromDate) + ' - ' + formatDateInternalUTC(toDate);
  }

  if (from) {
    return formatDateInternalUTC(fromDate);
  }

  if (to) {
    return formatDateInternalUTC(toDate);
  }

  return '';
}

export function formatDate(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return formatDateInternalUTC(dateObj);
}

export function formatDateTime(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return formatDateTimeInternalUTC(dateObj);
}

export function formatDateNonUTC(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return dateObj.toLocaleDateString();
}

export function formatDateTimeNonUTC(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return dateObj.toLocaleString();
}

function formatDateInternalUTC(date: Date): string {
  return transformToUtc(date).toLocaleDateString();
}

function formatDateTimeInternalUTC(date: Date): string {
  return transformToUtc(date).toLocaleString();
}

function transformToUtc(date: Date): Date {
  return new Date(date.toUTCString());
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
export function formatDateForInput(date?: Date): string | undefined {
  if (date === undefined) {
    return undefined;
  }

  return date.toISOString().substring(0, 10);
}

export const MILLISECONDS_PER_SECOND = 1_000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_MONTH = 30;
export const DAYS_PER_YEAR = 365;

export const SECONDS_PER_HOUR = MINUTES_PER_HOUR * SECONDS_PER_MINUTE;
export const SECONDS_PER_DAY = HOURS_PER_DAY * SECONDS_PER_HOUR;
export const SECONDS_PER_WEEK = DAYS_PER_WEEK * SECONDS_PER_DAY;
export const SECONDS_PER_MONTH = DAYS_PER_MONTH * SECONDS_PER_DAY;
export const SECONDS_PER_YEAR = DAYS_PER_YEAR * SECONDS_PER_DAY;

export function formatRelativeTimeUnder24Hours(date: Date, now: Date): string {
  const secondsSinceNow = Math.floor((now.getTime() - date.getTime()) / MILLISECONDS_PER_SECOND);

  if (secondsSinceNow / SECONDS_PER_DAY >= 1) {
    return formatLongDateTime(date);
  }

  let unitsOfTime = secondsToCumulativeUnits(secondsSinceNow, { includeMonths: false });

  if (secondsSinceNow / SECONDS_PER_HOUR >= 1) {
    delete unitsOfTime.minutes;
  }

  if (secondsSinceNow / SECONDS_PER_MINUTE >= 1) {
    delete unitsOfTime.seconds;
  }

  const formattedUnits = formatCumulativeUnits(unitsOfTime);

  if (formattedUnits === '0s') {
    return 'just now';
  }

  return formattedUnits + ' ago';
}

export function formatCumulativeUnits(unitsOfTime: CumulativeTimeUnits): string {
  let result: string = '';

  !!unitsOfTime.years && (result += unitsOfTime.years + 'yr ');
  !!unitsOfTime.months && (result += unitsOfTime.months + 'mo ');
  !!unitsOfTime.weeks && (result += unitsOfTime.weeks + 'wk ');
  !!unitsOfTime.days && (result += unitsOfTime.days + 'd ');
  !!unitsOfTime.hours && (result += unitsOfTime.hours + 'hr ');
  !!unitsOfTime.minutes && (result += unitsOfTime.minutes + 'min ');
  !!unitsOfTime.seconds && (result += unitsOfTime.seconds + 's ');

  if (result === '') {
    result = '0s';
  }

  return result.trimEnd();
}

export interface CumulativeTimeUnits {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export interface CumulativeTimeOptions {
  includeYears?: boolean;
  includeMonths?: boolean;
  includeWeeks?: boolean;
  includeDays?: boolean;
  includeHours?: boolean;
  includeMinutes?: boolean;
  includeSeconds?: boolean;
}

let defaultCumulativeTimeOptions: CumulativeTimeOptions = {
  includeYears: true,
  includeMonths: true,
  includeWeeks: true,
  includeDays: true,
  includeHours: true,
  includeMinutes: true,
  includeSeconds: true,
};

export function secondsToCumulativeUnits(seconds: number, options?: CumulativeTimeOptions): CumulativeTimeUnits {
  let secondsToFormat: number = seconds;
  let unitsOfTime: CumulativeTimeUnits = {};

  options = { ...defaultCumulativeTimeOptions, ...options };

  if (options.includeYears) {
    unitsOfTime.years = Math.floor(secondsToFormat / SECONDS_PER_YEAR);

    secondsToFormat -= unitsOfTime.years * SECONDS_PER_YEAR;
  }

  if (options.includeMonths) {
    unitsOfTime.months = Math.floor(secondsToFormat / SECONDS_PER_MONTH);

    secondsToFormat -= unitsOfTime.months * SECONDS_PER_MONTH;
  }

  if (options.includeWeeks) {
    unitsOfTime.weeks = Math.floor(secondsToFormat / SECONDS_PER_WEEK);

    secondsToFormat -= unitsOfTime.weeks * SECONDS_PER_WEEK;
  }

  if (options.includeDays) {
    unitsOfTime.days = Math.floor(secondsToFormat / SECONDS_PER_DAY);

    secondsToFormat -= unitsOfTime.days * SECONDS_PER_DAY;
  }

  if (options.includeHours) {
    unitsOfTime.hours = Math.floor(secondsToFormat / SECONDS_PER_HOUR);

    secondsToFormat -= unitsOfTime.hours * SECONDS_PER_HOUR;
  }

  if (options.includeMinutes) {
    unitsOfTime.minutes = Math.floor(secondsToFormat / SECONDS_PER_MINUTE);

    secondsToFormat -= unitsOfTime.minutes * SECONDS_PER_MINUTE;
  }

  if (options.includeSeconds) {
    unitsOfTime.seconds = secondsToFormat;
  }

  return unitsOfTime;
}

export function stripTimeFromDateString(date: string): string {
  return date.split('T')[0];
}

export function isDateValid(date?: Date): boolean {
  if (!!!date) {
    return false;
  }
  return !Number.isNaN(new Date(date).getTime());
}
