import { Dialog, Transition } from '@headlessui/react';
import {
  BookOpenIcon,
  CogIcon,
  DesktopComputerIcon,
  DownloadIcon,
  HomeIcon,
  InformationCircleIcon,
  KeyIcon,
  MapIcon,
  OfficeBuildingIcon,
  TagIcon,
  TicketIcon,
  UserIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Config from '../Config';
import { useCompanyProxy, useUserProfile } from '../global/UserCompanyContext';
import useHttp from '../hooks/useHttp';
import { useProxy } from '../hooks/useProxy';
import {
  useGetUsersLastViewedOperation,
  useUpdateUsersLastViewedOperation,
} from '../hooks/useUsersLastViewedOperation';
import { useOpenFreshworksWidget } from './FreshworksWidget';
import { EndProxyButton } from './admin/common';
import useSubTabRouteState, { AdminSubTab, OperationSubTab } from './attack/common/useSubTabRouteState';
import { mergeClasses } from './common';
import BASLogo from './common/BASLogo';
import CircleStackIcon from './common/icons/CircleStackIcon';
import NetSPIProvidedPlaybookIcon from './common/icons/NetSPIProvidedPlaybookIcon';

type MenuInfo = {
  title: string;
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>;
  path?: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  parentTab: string;
  subTabs?: SubMenuItem[];
};

interface SubMenuItem {
  title: string;
  path: string;
  isActive: boolean;
  state?: any;
}

export function EnvironmentHeader() {
  const className = Config.environment.toLowerCase() === 'development' ? 'bg-indigo-600' : 'bg-green-500';
  const isProduction = (Config.environment || '').toLowerCase() !== 'production' ? false : true;

  return isProduction ? null : (
    <div>
      <span className={mergeClasses('flex w-full justify-center py-1 font-medium text-white', className)}>
        {Config.environment.toUpperCase()}
      </span>
    </div>
  );
}

export function ProxyManager() {
  const [http, token] = useHttp();
  const { name, isProxy } = useCompanyProxy();
  const { endProxy } = useProxy();

  const className = 'bg-red-700 hover:bg-red-800';

  const handleEndProxy = () => {
    http.post('admin/user/proxy/end', undefined, { cancelToken: token }).then(() => endProxy());
  };
  return isProxy ? (
    <div className="flex w-full">
      <EndProxyButton
        className={mergeClasses(
          'flex w-full justify-center rounded-none border-0 border-transparent py-2 ring-0',
          className
        )}
        companyName={name}
        onClick={handleEndProxy}
      />
    </div>
  ) : null;
}
export interface SidebarProps {
  isMobileMenuOpen: boolean;
  setMobileMenuOpen(open: boolean): void;
}

export default function Sidebar({ isMobileMenuOpen, setMobileMenuOpen }: SidebarProps) {
  const { firstName, lastName } = useUserProfile();
  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      <MobileMenu name={fullName} isMobileMenuOpen={isMobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      <DesktopMenu name={fullName} />
    </>
  );
}
interface MenuProps {
  name: string;
  isMobileMenuOpen: boolean;
  setMobileMenuOpen(open: boolean): void;
}

function MobileMenu({ name, isMobileMenuOpen, setMobileMenuOpen }: MenuProps) {
  const handleOnClick = useCallback(() => {
    setMobileMenuOpen(false);
  }, [setMobileMenuOpen]);
  return (
    <Transition.Root show={isMobileMenuOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setMobileMenuOpen}>
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <EnvironmentHeader />
            <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <Link to="/" onClick={handleOnClick}>
                  <BASLogo className="h-8 w-auto" />
                </Link>
              </div>
              <nav className="mt-5 space-y-1 px-2">
                <MenuItems closeMenu={handleOnClick} />
              </nav>
            </div>
            <ProxyManager />
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <Link to="/profile" className="group block flex-shrink-0" onClick={handleOnClick}>
                <div className="flex items-center text-white">
                  <div>
                    <UserIcon className="inline-block h-10 w-10 rounded-full" />
                  </div>
                  <div className="ml-3">
                    <p className="text-base font-medium">{name}</p>
                    <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
      </Dialog>
    </Transition.Root>
  );
}

function DesktopMenu({ name }: Pick<MenuProps, 'name'>) {
  return (
    <>
      <div className="z-10 hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex min-h-0 flex-1 flex-col bg-gray-900">
          <EnvironmentHeader />
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <Link to="/">
                <BASLogo className="h-8 w-auto" />
              </Link>
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
              <MenuItems />
            </nav>
          </div>
          <ProxyManager />
          <div className="flex flex-shrink-0 bg-gray-700 p-4">
            <Link to="/profile" className="group block w-full flex-shrink-0">
              <div className="flex items-center text-white">
                <div>
                  <UserIcon className="inline-block h-9 w-9 rounded-full" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium">{name}</p>
                  <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">View profile</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export enum SidebarTab {
  DEFAULT = 'Default',
  HOME = 'Home',
  OPERATIONS = 'Operations',
  AGENTS = 'Agents',
  PLAYBOOKS = 'Playbooks',
  CREDENTIALS = 'Credentials',
  GUIDE = 'Guide',
  SUPPORT = 'Support',
  ADMIN = 'Admin',
  COMPANIES = 'Companies',
  USERS = 'Users',
  TAGS = 'Tags',
  IMPORT = 'Import',
  PROVIDED_PLAYBOOKS = 'Provided Playbooks',
  COMPANY_SETTINGS = 'Company Settings',
  DATA_SOURCE = 'Data Sources',
}

function MenuItems({ closeMenu }: { closeMenu?: () => void }) {
  const { isAdmin } = useUserProfile();
  const currentOperationId = useGetUsersLastViewedOperation();
  const showFreshworks = useOpenFreshworksWidget();
  const docsUrl = new URL('/docs', window.location.origin);
  const { activeSubTab, parentTab } = useSubTabRouteState();

  useUpdateUsersLastViewedOperation(currentOperationId);

  const operationSubMenuItems: SubMenuItem[] = [
    {
      title: 'Workspace',
      path: `/operation/${currentOperationId}/detection/workspace`,
      isActive: activeSubTab === OperationSubTab.Workspace,
    },
    {
      title: OperationSubTab.Run,
      path: `/operation/${currentOperationId}/run/plan`,
      isActive: activeSubTab === OperationSubTab.Run,
    },
    {
      title: OperationSubTab.Timeline,
      path: `/operation/${currentOperationId}/detection/timeline`,
      isActive: activeSubTab === OperationSubTab.Timeline,
    },
    {
      title: 'Heatmap',
      path: `/operation/${currentOperationId}/detection/heatmap`,
      isActive: activeSubTab === OperationSubTab.Heatmap,
    },
    {
      title: 'Coverage',
      path: `/operation/${currentOperationId}/coverage/vendor`,
      isActive: activeSubTab === OperationSubTab.Coverage,
    },
    {
      title: 'Activity Log',
      path: `/operation/${currentOperationId}/detection/activity`,
      isActive: activeSubTab === OperationSubTab.ActivityLog,
      state: { resetFilters: true },
    },
  ];

  if (isAdmin) {
    operationSubMenuItems.push({
      title: OperationSubTab.Reports,
      path: `operation/${currentOperationId}/reports`,
      isActive: activeSubTab === OperationSubTab.Reports,
    });
  }

  let OperationSidebarTab = (
    <MenuItem
      title={SidebarTab.OPERATIONS}
      path="/operation"
      icon={MapIcon}
      closeMenu={closeMenu}
      parentTab={parentTab}
      subTabs={operationSubMenuItems}
    />
  );

  const datasourceSubMenuItems: SubMenuItem[] = [
    {
      title: AdminSubTab.AttackSurface,
      path: `/admin/attacksurface`,
      isActive: activeSubTab === AdminSubTab.AttackSurface,
    },
    {
      title: AdminSubTab.DataComponent,
      path: `/admin/datacomponent`,
      isActive: activeSubTab === AdminSubTab.DataComponent,
    },
    {
      title: AdminSubTab.DataSource,
      path: `/admin/datasource`,
      isActive: activeSubTab === AdminSubTab.DataSource,
    },
    {
      title: AdminSubTab.DetectionStrategy,
      path: `/admin/detectionstrategy`,
      isActive: activeSubTab === AdminSubTab.DetectionStrategy,
    },
    {
      title: AdminSubTab.NotificationStrategy,
      path: `/admin/notificationstrategy`,
      isActive: activeSubTab === AdminSubTab.NotificationStrategy,
    },
    {
      title: AdminSubTab.SecurityControlType,
      path: `/admin/securitycontroltype`,
      isActive: activeSubTab === AdminSubTab.SecurityControlType,
    },
    {
      title: AdminSubTab.SecurityVendor,
      path: `/admin/securityvendor`,
      isActive: activeSubTab === AdminSubTab.SecurityVendor,
    },
  ];

  let DataSourceSidebarTab = (
    <MenuItem
      title={SidebarTab.DATA_SOURCE}
      path="/admin/datasource"
      icon={CircleStackIcon}
      closeMenu={closeMenu}
      parentTab={parentTab}
      subTabs={datasourceSubMenuItems}
    />
  );

  const companySettingsSubMenuItems: SubMenuItem[] = [
    {
      title: AdminSubTab.Industries,
      path: `/admin/industry`,
      isActive: activeSubTab === AdminSubTab.Industries,
    },
    {
      title: AdminSubTab.SalesPackage,
      path: `/admin/salespackage`,
      isActive: activeSubTab === AdminSubTab.SalesPackage,
    },
  ];

  let CompanySettingsSidebarTab = (
    <MenuItem
      title={SidebarTab.COMPANY_SETTINGS}
      path="/admin/industry"
      icon={CogIcon}
      closeMenu={closeMenu}
      parentTab={parentTab}
      subTabs={companySettingsSubMenuItems}
    />
  );

  return (
    <>
      <MenuItem title={SidebarTab.HOME} path="/" icon={HomeIcon} closeMenu={closeMenu} />
      {OperationSidebarTab}
      <MenuItem title={SidebarTab.AGENTS} path="/agent" icon={DesktopComputerIcon} closeMenu={closeMenu} />
      <MenuItem title={SidebarTab.PLAYBOOKS} path="/playbook" icon={BookOpenIcon} closeMenu={closeMenu} />
      <MenuItem title={SidebarTab.CREDENTIALS} path="/credential" icon={KeyIcon} closeMenu={closeMenu} />
      <MenuItem title={SidebarTab.GUIDE} path={docsUrl.toString()} icon={InformationCircleIcon} closeMenu={closeMenu} />
      <MenuItem title={SidebarTab.SUPPORT} onClick={showFreshworks} icon={TicketIcon} closeMenu={closeMenu} />
      {isAdmin ? (
        <>
          <MenuItem title={SidebarTab.ADMIN} />
          <MenuItem
            title={SidebarTab.COMPANIES}
            path="/admin/company"
            icon={OfficeBuildingIcon}
            closeMenu={closeMenu}
          />
          <MenuItem title={SidebarTab.USERS} path="/admin/user" icon={UsersIcon} closeMenu={closeMenu} />
          <MenuItem title={SidebarTab.TAGS} path="/admin/tag" icon={TagIcon} closeMenu={closeMenu} />
          <MenuItem title={SidebarTab.IMPORT} path="/admin/import" icon={DownloadIcon} closeMenu={closeMenu} />
          <MenuItem
            title={SidebarTab.PROVIDED_PLAYBOOKS}
            path="admin/playbook"
            icon={NetSPIProvidedPlaybookIcon}
            closeMenu={closeMenu}
          />
          {DataSourceSidebarTab}
          {CompanySettingsSidebarTab}
        </>
      ) : null}
    </>
  );
}

const LinkClassName = 'group flex items-center px-2 py-2 text-base font-medium rounded w-full';
const ActionableLinkClassName = 'hover:bg-gray-700 hover:text-white';
const LinkActiveClassName = 'bg-gray-700 text-white';
const LinkInactiveClassName = 'text-gray-400';

function MenuItem({
  title,
  path,
  onClick,
  icon,
  closeMenu,
  parentTab,
  subTabs,
}: Partial<MenuInfo> & { closeMenu?: () => void }) {
  const isActionable = path !== undefined || onClick !== undefined;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const getContainerClass = React.useCallback(
    ({ isActive }: { isActive: boolean }) =>
      mergeClasses(
        { className: LinkClassName, enable: true },
        { className: ActionableLinkClassName, enable: isActionable },
        { className: LinkActiveClassName, enable: isActive },
        { className: LinkInactiveClassName, enable: !isActive }
      ),
    [isActionable]
  );

  const getIconClass = React.useCallback(
    (isActive: boolean) =>
      mergeClasses(
        { className: 'mr-2 flex-shrink-0 h-6 w-6', enable: true },
        { className: 'group-hover:text-gray-300', enable: isActionable },
        { className: 'text-gray-300', enable: isActive },
        { className: 'text-gray-400', enable: !isActive }
      ),
    [isActionable]
  );

  const btnOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onClick !== undefined) {
        onClick(e);
      }

      if (closeMenu !== undefined) {
        closeMenu();
      }
    },
    [closeMenu, onClick]
  );

  useEffect(() => {
    if (parentTab === title) {
      setIsExpanded(true);
    }
  }, [parentTab, title]);

  if (!isActionable) {
    return (
      <span className={getContainerClass({ isActive: false })}>
        <MenuItemContent title={title} icon={icon} getIconClass={getIconClass} />
      </span>
    );
  }

  if (path === undefined) {
    return (
      <button className={getContainerClass({ isActive: false })} onClick={btnOnClick} type="button" title={title}>
        <MenuItemContent title={title} icon={icon} getIconClass={getIconClass} />
      </button>
    );
  }

  return path.indexOf('http://') === 0 || path.indexOf('https://') === 0 ? (
    <a
      href={path}
      target="_blank"
      rel="noreferrer"
      className={getContainerClass({ isActive: false })}
      onClick={closeMenu}
    >
      <MenuItemContent title={title} icon={icon} getIconClass={getIconClass} />
    </a>
  ) : (
    <div>
      <NavLink to={path} className={getContainerClass} onClick={closeMenu}>
        {({ isActive }) => (
          <div className="flex grow flex-row">
            <div className="flex grow">
              <MenuItemContent title={title} icon={icon} isActive={isActive} getIconClass={getIconClass} />
            </div>
            {subTabs && (
              <button
                type="button"
                className="flex"
                onClick={(event) => ToggleSubMenu(isExpanded ?? true, setIsExpanded, event)}
                title="toggle sub menu"
              >
                {isExpanded ? (
                  <ChevronUpIcon className="h-6 w-6 text-gray-400" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-gray-400" />
                )}
              </button>
            )}
          </div>
        )}
      </NavLink>
      {subTabs && isExpanded && (
        <div className="mt-3 flex flex-col">
          {subTabs.map((subTab, index) => (
            <SubMenuItemContent key={index} subTab={subTab} />
          ))}
        </div>
      )}
    </div>
  );
}

const SubLinkClassName = LinkClassName + ' ' + LinkInactiveClassName;
const SubLinkActiveClassName = 'text-white';

const SubMenuItemContent = ({ subTab }: { subTab: SubMenuItem }) => {
  const getSubMenuItemContainerClass = React.useCallback(
    ({ isActive }: { isActive: boolean }) =>
      mergeClasses(
        { className: SubLinkClassName, enable: true },
        { className: SubLinkActiveClassName, enable: isActive }
      ),
    []
  );

  return (
    <div className="-mt-3 flex">
      {subTab.isActive && <div className="ml-[14px] flex items-center text-2xl text-primary-600">•</div>}
      <div className={mergeClasses('flex', subTab.isActive ? 'ml-2' : 'ml-8')}>
        <NavLink
          to={subTab.path}
          state={subTab?.state}
          className={getSubMenuItemContainerClass({ isActive: subTab.isActive })}
        >
          {subTab.title}
        </NavLink>
      </div>
    </div>
  );
};

type MenuItemContentProps = Pick<Partial<MenuInfo>, 'icon' | 'title'> & {
  isActive?: boolean;
  getIconClass(isActive: boolean): string;
};

function MenuItemContent({ getIconClass, isActive, icon, title }: MenuItemContentProps) {
  const Icon = icon;

  return (
    <>
      {Icon === undefined ? null : <Icon className={getIconClass(isActive ?? false)} aria-hidden="true" />}
      {title}
    </>
  );
}

function ToggleSubMenu(
  isExpanded: boolean,
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>> | undefined,
  event: React.MouseEvent
): void {
  event.preventDefault();

  if (setIsExpanded !== undefined) {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);
  }
}
