import { Location, NavigateFunction, NavigateProps } from 'react-router-dom';
import { NavigateParams } from '../../common';

const AnimationKeyState = { routerAnimationKey: 'AttackSimulationExecutePage' };

export function ensureAttackAnimationState(navigate: NavigateFunction, location: Location) {
  const locState = location.state as typeof AnimationKeyState | undefined;
  if (locState?.routerAnimationKey !== AnimationKeyState.routerAnimationKey) {
    navigate(location.pathname, { state: { ...locState, ...AnimationKeyState } });
  }
}

export function withAttackAnimationState(location: string | NavigateParams): NavigateParams {
  let descriptor: NavigateParams = typeof location === 'string' ? { to: { pathname: location } } : location;
  return {
    to: descriptor.to,
    replace: descriptor.replace,
    state: { ...descriptor.state, ...AnimationKeyState },
  };
}

export function getHomeLocation(): NavigateParams {
  return { to: { pathname: '/' } };
}

export function getOperationsIndexLocation(): NavigateParams {
  return { to: { pathname: '/operation' } };
}

export function getNewOperationLocation(state?: any): NavigateParams {
  return { to: { pathname: '/operation/new' }, state: state };
}

export function getOperationEditLocation(id: number, state?: any): NavigateParams {
  return { to: { pathname: '/operation/' + id }, state: state };
}

export function getOperationLandingLocation(id: number, tab?: 'plan' | 'execute' | 'history'): NavigateParams {
  return { to: { pathname: '/operation/' + id + '/run/' + (tab ?? 'plan') } };
}

export function getOperationAgentSettingsLocation(id: number, agent: string, location: Location): NavigateProps {
  const path = parseOperationPath(location.pathname);
  return { to: { pathname: `/operation/${id}/run/${path?.tab ?? 'plan'}/agent/${agent}` } };
}

export function getAttackPlaybookIndexLocation(): NavigateParams {
  return { to: { pathname: '/playbook' } };
}

export function getAttackPlaybookLocation(id: number): NavigateParams {
  return { to: { pathname: `/playbook/${id}` } };
}

export function getNewAttackPlaybookLocation(): NavigateParams {
  return { to: { pathname: '/playbook/add' } };
}

export function getCredentialIndexLocation(): NavigateParams {
  return { to: { pathname: `/credential` } };
}

export function getCredentialLocation(id: string): NavigateParams {
  return { to: { pathname: `/credential/${id}` } };
}

export function getNewCredentialLocation(): NavigateParams {
  return { to: { pathname: '/credential/add' } };
}

export function getPlaysIndexLocation(): NavigateParams {
  return { to: { pathname: `/play` } };
}

export function getPlaysLandingLocation(id: number | string): NavigateParams {
  return { to: { pathname: `/play/${id}` } };
}

export function getAgentsLocation(): NavigateParams {
  return { to: { pathname: '/agent' } };
}

export function getAgentFormPageLocation(id: string): NavigateParams {
  return { to: { pathname: `/agent/${id}` } };
}

export function parseOperationPath(path: string) {
  const re = /(\/operation\/(\d+)\/run\/(\w+))\/?/;
  const results = path.match(re);
  if (results === null) {
    return null;
  }

  return {
    path: results[1],
    id: Number(results[2]),
    tab: results[3],
  };
}
