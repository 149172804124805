import React, { TextareaHTMLAttributes } from 'react';
import { mergeClasses } from '.';

type TextareaThemeName = 'standard' | 'error';
interface InputThemeInfo {
  textarea: string;
}

export type InputTheme = TextareaThemeName | InputThemeInfo;

const DefaultClassName = 'shadow-sm block w-full sm:text-sm rounded';

const themes: Record<TextareaThemeName, InputThemeInfo> = {
  standard: { textarea: DefaultClassName + ' focus:ring-primary focus:border-primary border-gray-400 rounded' },
  error: {
    textarea:
      DefaultClassName +
      'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ',
  },
};

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  theme: InputTheme;
};

export default React.forwardRef(function Input(
  { className, theme, ...props }: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  if (typeof theme === 'undefined') {
    theme = 'standard';
  }

  let info: InputThemeInfo;
  if (typeof theme === 'string') {
    info = themes[theme];
    if (info === undefined) {
      throw Error('Invalid theme: ' + theme);
    }
  } else {
    info = theme;
  }

  return <textarea {...props} className={mergeClasses(className, info.textarea)} ref={ref} />;
});
