import { Tab } from '@headlessui/react';
import React, { useEffect } from 'react';
import { mergeClasses } from '.';

export interface PageTab {
  button: React.ReactNode | ((active: boolean) => React.ReactNode);
  panel: React.ReactNode;
}

export interface PageTabbedSectionProps {
  actionsSection?: JSX.Element;
  align?: 'left' | 'right';
  className?: string;
  defaultTab?: number;
  panelClassName?: string;
  tabs: PageTab[];
  tablistClassName?: string;
  title?: string | React.ReactNode;
  onChange?(index: number): void;
}

const TabButtonClasses =
  'whitespace-nowrap py-2 px-2 first:pl-0 border-b-2 font-medium text-sm focus-visible:outline-primary ';
const TabButtonActiveClasses = TabButtonClasses + 'border-primary text-primary';
const TabButtonInactiveClasses =
  TabButtonClasses + 'border-transparent text-gray-600 hover:text-gray-800 hover:border-gray-400';

export default function PageTabbedSection({
  actionsSection,
  align,
  className,
  defaultTab,
  panelClassName,
  tabs,
  tablistClassName,
  title,
  onChange,
}: PageTabbedSectionProps) {
  useEffect(() => {
    if (align === 'left' && title !== undefined) {
      console.warn('Unable to left align tabs when a title is provided.');
    }
  }, [align, title]);

  const rightAligned = title === undefined && align === 'right';
  return (
    <Tab.Group defaultIndex={defaultTab} onChange={onChange}>
      <div className={mergeClasses('drop-shadow-none filter', className)}>
        <div
          className={mergeClasses(
            'flex flex-col justify-between rounded-t border border-transparent border-b-gray-400 bg-white px-3 pt-4 shadow-none sm:flex-row sm:items-start sm:px-6',
            {
              className: 'sm:justify-end',
              enable: rightAligned,
            },
            tablistClassName
          )}
        >
          {title === undefined ? null : (
            <div className="relative -bottom-px flex-1">
              <h1 className="flex text-lg font-semibold text-gray-800">{title}</h1>
            </div>
          )}
          <div className="flex">
            <Tab.List className="relative -bottom-px self-end">
              {tabs.map((t, i) => (
                <Tab
                  key={i}
                  className={({ selected }) => (selected ? TabButtonActiveClasses : TabButtonInactiveClasses)}
                >
                  {({ selected }) => (typeof t.button === 'function' ? t.button(selected) : t.button)}
                </Tab>
              ))}
            </Tab.List>
          </div>
          {actionsSection === undefined ? null : (
            <div className="flex items-center space-x-2 space-y-0">{actionsSection}</div>
          )}
        </div>

        <Tab.Panels className={mergeClasses('rounded-b bg-white p-3 pb-6 sm:px-6', panelClassName)}>
          {tabs.map((t, i) => (
            <Tab.Panel key={i} className="focus-visible:outline-primary">
              {t.panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
}
