import { ErrorBoundary } from './index';

interface RichTextRendererProps {
  model: string;
}

export default function RichTextRenderer({ model }: RichTextRendererProps) {
  return (
    <ErrorBoundary fallback={<p>There was an error loading the content. Please exit and try again.</p>}>
      <div className="rich-text-renderer" dangerouslySetInnerHTML={{ __html: model }} />
    </ErrorBoundary>
  );
}
