import { LoadingIndicator, PageTemplate, mergeClasses } from '../common';
import useProfileForm from './utils/useProfileForm';
import { NavLink, Outlet, To } from 'react-router-dom';
import { Suspense } from 'react';

const UserProfile: React.FC = () => {
  const { loading, isExternalLogin } = useProfileForm();

  return (
    <PageTemplate loading={loading} title="User Profile">
      <div className="bg-white rounded-l pl-1">
        <div className="flex flex-col border-b border-gray-400 bg-white pt-3 pl-6 sm:flex-row sm:items-start">
          <div className="relative -bottom-px flex w-full self-end pt-2 pr-6">
            <TabButton to="general" text={'General'} />
            {!isExternalLogin && <TabButton to="mfa" text={'MFA'} />}
            <TabButton to="api" text={'API'} />
          </div>
        </div>
        <div className="bg-white p-3 sm:px-2">
          <Suspense fallback={<LoadingIndicator />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </PageTemplate>
  );
};

function TabButton({ to, text }: { to: To; text: string }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        mergeClasses(
          'whitespace-nowrap border-b-2 py-2 px-2 text-sm first:pl-0 focus-visible:outline-primary',
          { className: 'border-primary text-primary', enable: isActive },
          {
            className: 'border-transparent text-gray-600 hover:border-gray-400 hover:text-gray-800',
            enable: !isActive,
          }
        )
      }
    >
      {() => <>{text}</>}
    </NavLink>
  );
}

export default UserProfile;
