import { Button } from './common';
import { RefreshIcon } from '@heroicons/react/solid';
import { ReactNode } from 'react';
import { useAppVersion } from '../hooks/useAppVersion';

interface AppErrorProps {
  children?: ReactNode;
}

export default function AppError({ children }: AppErrorProps) {
  const { isAppVersionUpdated } = useAppVersion();
  return (
    <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
      <div className="my-auto flex-shrink-0 py-16 sm:py-32">
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-800 sm:text-5xl">There was an error</h1>
        {children === undefined ? (
          <>
            {isAppVersionUpdated ? (
              <p className="mt-2 text-base">
                We apologize, but an unexpected error has occurred. We have recorded the details of the error and will
                resolve the issue as soon as possible. If you continue to encounter the error{' '}
                <a
                  href="mailto:info@netspi.com?subject=NetSPI%20AttackSim%20Error%20Report"
                  className="text-primary hover:text-primary-600 hover:underline"
                >
                  please contact support
                </a>
                .
              </p>
            ) : (
              <p className="mt-2 text-base">
                We apologize, but an unexpected error has occurred which is likely caused by a version mismatch.
              </p>
            )}
            <p className="mt-2 text-base">Please reload the page to continue.</p>
            <div className="mt-6">
              <Button href="/">
                <RefreshIcon className="-ml-1 mr-3 h-5 w-5" /> Reload
              </Button>
            </div>
          </>
        ) : (
          <p>{children}</p>
        )}
      </div>
    </main>
  );
}
