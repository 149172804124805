import React from 'react';
export const AgentIndex = React.lazy(() => import('./agents/AgentIndex'));
export const AgentForm = React.lazy(() => import('./common/AgentForm'));
export const CredentialFormPage = React.lazy(() => import('./credentials/CredentialForm'));
export const CredentialIndexPage = React.lazy(() => import('./credentials/CredentialIndex'));
export const PlaybookFormPage = React.lazy(() => import('./playbooks/PlaybookForm'));
export const PlaybookIndexPage = React.lazy(() => import('./playbooks/PlaybookIndex'));
export const OperationIndex = React.lazy(() => import('./operations/OperationIndex'));
export const OperationsLanding = React.lazy(() => import('./operations/OperationLanding'));
export const OperationForm = React.lazy(() => import('./operations/OperationForm'));
export const OperationPlanTab = React.lazy(() => import('./operations/OperationPlanTab'));
export const OperationExecuteTab = React.lazy(() => import('./operations/OperationExecuteTab'));
export const OperationHistoryTab = React.lazy(() => import('./operations/OperationHistoryTab'));
export const RunOperationTab = React.lazy(() => import('./operations/OperationRun'));
export const OperationHome = React.lazy(() => import('./operations/OperationHome'));
